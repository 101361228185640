import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import ContactButton from "../dark/contactbutton";
import './customStyles.scss';



const RealEstate = () => {


    return (

        <div className=''>
            {/* Start Service Area  */}
            <div id="services" className="fix">
                <div className="service-area creative-service-wrapper pb--20 pt--70 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>
                                    {/*Section 1*/}
                                    <div className="titleMainheder">
                                        <h4 className="title mb-3">Real Estate </h4>
                                    </div>
                                    <div className='px-3 paracenter'>
                                        
                                            
                                            <p className="para2 p-2">Indibridge helps real estate companies to build and integrate custom software solutions tailored to unique nuances of their business and processes.</p>
                                        <p className="para2 p-2">As a full-service product development company, we work with both commercial and residential real estate startups and enterprises helping them build innovative mobile and web applications, customize off-the-shelf software, move and optimize their solutions in the cloud, support and maintain existing applications. Our skilled business analysts, software development engineers, and quality assurance specialists help clients turn emerging technologies like AI and Machine Learning, Blockchain, Big Data and Predictive Analytics into disruptive products.</p>
                                        <p className="para2 p-2">Real Estate has been one of the constantly evolving and fastest-growing industries in the past several years. Changing consumer behaviours and increasing expectation to get immediate answers at their fingertips puts the immense pressure on both commercial and residential real estate companies to innovate. Online listings, virtual tours and e-signing are becoming a norm in the industry. Several key trends in consumer behaviour are reshaping real estate even further pushing companies to adapt to effectively compete on the market.</p>
                                        <p className="para2 p-2">Digital Transformation of traditional real estate companies can help automate mundane workflows, optimize operations, comply with regulations and improve productivity and profitability, and, ultimately, provide exceptional consumer
                                            experience.
                                        </p> 
                                        
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end Section 1*/}
                {/*Section 2*/}

                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="container section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
      
                                        <h4 className=' title1 mb-3 mt-2'> Real Estate Software Development Expertise</h4>
                                        <hr/>
                                    </div>
                                    <div class="mt-1 px-1 text-center">
                                        <div class="row mb-3 justify-content-center">
                                            <div class="col-sm  expertises  btn-primary ml-1 py-3">
                                                
                                                <h6 className="headbgb mt-3 pb-2" >COMMERCIAL PROPERTY MANAGEMENT & MAINTENANCE SOFTWARE</h6>
                                            </div>
                                            <div class="col-sm expertises  btn-primary ml-1 py-3">
                                                
                                                <h6 className="headbgb mt-3 pb-2">RESIDENTIAL REAL-TIME TENANT & LEASE MANAGEMENT SOLUTIONS</h6>
                                            </div>
                                            <div class="col-sm expertises  btn-primary ml-1 py-3">
                                                
                                                <h6 className="headbgb mt-3 pb-2">WEB & MOBILE APPLICATIONS FOR REAL ESTATE AGENTS</h6>
                                            </div>
                                            <div class="col-sm  expertises  btn-primary ml-1 py-3">
                                                
                                                <h6 className="headbgb mt-3 pb-2">REAL ESTATE CRM & LEAD GENERATION PLATFORMS</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-1 px-1 text-center">
                                        <div class="row mb-3 justify-content-center">
                                            <div class="col-sm  expertises  btn-primary ml-1 py-3">
                                               
                                                <h6 className="headbgb mt-3 pb-2">REAL ESTATE WORKFLOW SOLUTIONS</h6>
                                            </div>
                                            <div class="col-sm expertises btn-primary ml-1 py-3">
                                                
                                                <h6 className="headbgb mt-3 pb-2">MLS & IDX INTEGRATION</h6>
                                            </div>
                                            <div class="col-sm expertises  btn-primary ml-1 py-3">
                                               
                                                <h6 className="headbgb mt-3 pb-2">360 VIRTUAL VIEW OF A PROPERTY OR A BUILDING</h6>
                                            </div>
                                            <div class="col-sm expertises  btn-primary ml-1 py-3">
                                                
                                                <h6 className="headbgb mt-3 pb-2">VIRTUAL OFFICE SOLUTIONS</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-1 px-1 text-center">
                                        <div class="row mb-3 justify-content-center">
                                            <div class="col-sm-3 expertises btn-primary ml-1 py-3">
                                               
                                                <h6 className="headbgb mt-3 pb-2">LISTING RECOMMENDATION ENGINES</h6>
                                            </div>
                                            <div class="col-sm-3  expertises  btn-primary ml-1 py-3">
                                               
                                                <h6 className="headbgb mt-3 pb-2">MARKETING & ADVERTISING SOLUTIONS FOR REAL ESTATE</h6>
                                            </div>
                                            <div class="col-sm-3  expertises  btn-primary ml-1 py-3">
                                               
                                                <h6 className="headbgb mt-3 pb-2">BIG DATA & PREDICTIVE ANALYTICS</h6>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>


                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 2*/}
                {/*  Section 3*/}
                <div className="service-area creative-service-wrapper ptb--20 bg-white text-{#002060}">
                <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        
                                        <h4 className=' title mb-3 mt-2'> Real Estate Software Development Services</h4>
                                        <hr/>
                                    </div>

                                    <div className="rowpx-4">
                                        <div className="col-sm px-4 ">
                                            <ul className='listalign'>  
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Discovery and Analysis</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Solution Architecture</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Front-end Development</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>UX/UI Design</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>POC and MVP development services</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Custom Web Software Development</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm px-4 ">

                                            <ul className='listalign'>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Mobile Application development</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>QA Testing and Automation</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>DevOps Implementation</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Cloud Migration and Optimization</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Team Augmentation</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>24x7 Support and Maintenance</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 3*/}

                
            </div >
            <div div className='p-3' > <ContactButton /></div>
        </div >
    )
}

export default RealEstate;
