import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import ContactButton from "../dark/contactbutton";
import './customStyles.scss';



const Digitaltransservice = () => {


    return (

        <div className=''>
            {/* Start Service Area  */}
            <div id="services" className="fix">
                <div className="service-area creative-service-wrapper pt--70 pb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>
                                    {/*Section 1*/}
                                    <div className="titleMainheder">
                                        <h4 className="title mb-3">Digital Transformation</h4>
                                    </div>
                                    <div className="  paracenter ">
                                        <div>
                                            <p className="para2 pt-2 ">Indibridge is a premier full-service Custom Software Development, Cloud Engineering, QA and DevOps company, specializing in the development of time-sensitive and innovative solutions. To every project indibridge brings a combination of domain expertise, exceptional engineering talent, rigorous Agile development processes, commitment to protection of client’s IP and accountability.</p>
                                            <p className="para2 pt-2 ">Digital transformation is constantly evolving journey, not a destination. You need an experienced team to help you take advantage of new technologies and reap the benefits of being a digital enterprise.
                                                Indibridge’s Digital Transformation Practice draws on our decades-long experience in converting legacy systems into cutting-edge architectural environments. We’re experts in breaking down internal silos, moving and improving applications in the cloud, accelerating custom software development, processes, and orchestrating a data-driven approach to digital services delivery.
                                                Team up with indibridge to accelerate your journey to the cloud, deliver new customer experiences, redesign business models, and improve operational efficiency. We promptly act on your vision and realize it in a secure, reliable and scalable way.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end Section 1*/}
                {/*Section 2*/}

                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>

                                    <div className="text-center1">
                                        <h4 className=' title1 mb-3 mt-2'> Our Digital Transformation Offerings</h4>
                                        <hr />
                                    </div><br />

                                    <div className="row ">
                                        <div className="col-sm px-4 ">
                                            <ul class="fa-ul listalign" >
                                                <li className='text-white' ><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Legacy Application Modernization</li>
                                                <li className='text-white'><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Cloud Migration and Enablement</li>
                                                <li className='text-white'><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Greenfield Cloud Engineering</li>
                                                <li className='text-white'><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Automation, DevOps Services</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm px-4 ">

                                            <ul className='listalign'>
                                                <li className='text-white'><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Big Data Warehousing</li>
                                                <li className='text-white'><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Business Intelligence Solutions</li>
                                                <li className='text-white'><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Predictive Analytics</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 2*/}
                {/*  Section 3*/}
                <div className="service-area creative-service-wrapper ptb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>
                                    {/*Digital Transformation Services*/}
                                    <div className="text-center1">
                                        {/*<h4 className=' title mb-3 mt-2'><i class="bi bi-check-circle-fill px-2"></i> <span> Moder</span>nise your core Application</h4> */}
                                        <h4 className=' title mb-3 mt-2'>  Modernise your core Application</h4>
                                        <hr />
                                    </div>
                                    <div className="row pxrow">
                                        <div className="col-sm-7 px-4 ">
                                            <div id="dts">
                                                <p className="para2">Legacy applications have become one of the biggest impediments to business agility and ability to compete in today’s digital economy. Many companies struggle with revenue losses and operational inefficiencies due to limitations in workflow automation, deteriorating user
                                                    experiences, lack of real-time analytics and reporting, and inability to integrate with emerging technologies. Modernizing and Moving legacy applications to the cloud offers several business-critical benefits:
                                                </p>
                                                <ul className='listalign'>
                                                    <li><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Operational and technology cost reduction</li>
                                                    <li><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Improved business agility</li>
                                                    <li><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Enhanced team collaboration and increased performance</li>
                                                    <li><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Improved software compliance</li>
                                                    <li><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Better security</li>

                                                </ul>
                                                <p className="para2">Indibridge has been modernizing and converting legacy systems into modern, scalable and secure solutions. In the digital era Cloud offers a superior opportunity to achieve faster time to market; release stable, secure, and scalable products more often to adapt to quickly changing customer demands; leverage data potential to create personalized experiences and improve business performance metrics. Moving existing legacy infrastructure or separate mission-critical applications to the Cloud by leveraging existing open-source tools.</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-5  ">

                                            <img className=" futureimg w-250   p-4" src="./assets/images/about/Future.png" alt="Future Images" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 3*/}
                {/* start Section 4*/}
                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>
                                    {/*Digital Transformation Services*/}
                                    <div className="text-center1">
                                        <h4 className='title1 mb-3 mt-2'>   Future Proof your Business Critical Applications with Cloud Agnostic Solution</h4>
                                        <hr />


                                    </div>
                                    <div className="row">
                                        <div className="col-sm-5 px-4 ">
                                            <div className='text-center'>
                                                <img className=" futureimg w-150  mt-4   " src="./assets/images/about/microsoft.png" alt="microsoft Images" />
                                            </div><br />
                                            <div className='text-center'>
                                                <img className=" futureimg w-150  mt-5  " src="./assets/images/about/aws.png" alt="aws Images" />
                                            </div><br />
                                            <div className='text-center'>
                                                <img className=" futureimg w-150  mt-5   " src="./assets/images/about/googleCloud.png" alt="googleCloud Images" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 px-4 ">
                                            <div id="dts">

                                                <p className='para1b pt-2'>Cloud is a key pillar of any Digital Transformation journey. It provides scale and speed that is required for organization to focus on transformation.</p>
                                                <p className='para1b pt-2'>Whether you choose AWS, Google, Azure or Hybrid cloud, Indibridge will assess business requirements and plan a predictive move to the cloud tailored to your technology needs, business goals and budgets. </p>
                                                <p className='para1b pt-2'>There’re many approaches to cloud migration. One of the most popular, “Lift and Shift”, in many cases has proven to be very costly and inefficient for organizations with complex architectural environments and third-party integrations. On the other hand, a full refactoring might turn into a lengthy, expensive, and unnecessary endeavour.</p>
                                                <p className='para1b pt-2'>Indibridge’s approach to cloud migration allows us to leverage powerful open-source tools to extract the most value for your business by providing scalability and security while staying within budget by assessing your existing infrastructure and applications and developing a customized migration plan based on particular application technology
                                                    and architecture.
                                                </p>
                                                <p className='para1b pt-2'>If you are concerned about cloud lock-in, an easy way to become cloud-agnostic is by utilizing open-source containers and container management tools like Docker or Kubernetes. Application containerization offers control and deployment agility with the option to run across on-premises, private or public clouds</p>
                                                <p className='para1b pt-2'>In some cases, the complexity of legacy systems and adherence to stringent regulatory requirements are two key factors that make a complete move to public cloud services unfeasible. In this case, Hybrid Cloud is a great solution to not only reap the benefits of cutting-edge technology but also continue utilizing legacy system components that are
                                                    mission-critical to the organization.
                                                </p>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 4*/}

                {/* Start Section 5*/}
                <div className="service-area creative-service-wrapper ptb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="titlecenter">
                                        <h4 className=' title mb-3 mt-2 '>  Agile DevOps Services</h4>
                                        <hr />
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6 col-md-6 col-lg-6  ml-1 ">

                                            <p className='para2 pt-2'>Digital Transformation is hard to achieve without a solid infrastructure automation foundation and close collaboration between teams. Pairing Cloud with DevOps allows organizations to speed the pace of innovation and better respond to continuous change in market conditions and evolving customer demands. DevOps helps to enable rapid prototyping and faster, more stable software and services delivery.</p>
                                            <p className='para2 pt-2'>Whether you need to implement one area of DevOps or looking for a turn-key DevOps-as-a-Service partner, Indibridge has necessary skills and expertise to help your team implement DevOps practices from defining workflows and tools, scripting, performance optimization, process automation, continuous testing to CI/CD implementation.</p>
                                            <p className='para2 pt-2'>Whether you need to implement one area of DevOps or looking for a turn-key DevOps-as-a-Service partner, Indibridge has necessary skills and expertise to help your team implement DevOps practices from defining workflows and tools, scripting, performance optimization, process automation, continuous testing to CI/CD implementation.</p>

                                        </div>
                                        <div className="col-sm-6 col-md-5 col-lg-5  ">
                                            <img className="agileDev " src="./assets/images/bg/AgileDevOpsServices.jpg" alt="AgileDevOpsServices Images" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 4*/}
            </div >
            <div div className='p-3' > <ContactButton /></div>
        </div >
    )
}

export default Digitaltransservice;
