// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

// Create Import File
import './index.scss';

// Dark Home Layout 
import DarkPortfolioLanding from './dark/PortfolioLanding';
import About from './dark/about';
import Telecom from './dark/telecom';
import Clients from './dark/clients';
import Contact from './dark/contact';
import Verticals from './dark/verticals';


import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import HeaderThree from './component/header/HeaderThree';
import FooterTwo from './component/footer/FooterTwo';
import Ourservices from './dark/ourservices';
import Engagementmodels from './dark/engagementmodels';
import Customsoftwareservice from './dark/customsoftwareservice';
import Digitaltransservice from './dark/digitaltransservice';
import Softproductdevp from './dark/softproductdevp';
import Mobileappdev from './dark/mobileappdev';
import Qatesting from './dark/qatesting';
import Frontenddevp from './dark/frontenddevp';
import Appmaintsupport from './dark/appmaintsupport';
import Devopsservice from './dark/devopsservice';
import SoftDevStartups from "./dark/softDevStartups";
import SoftDevBFSI from "./dark/softDevBFSI";
import EducationELearning from "./dark/educationELearning";
import RealEstate from "./dark/realEstate";
import TravelandHospitality from "./dark/travelandHospitality";
import SoftwareSecurityServices from "./dark/softwareSecurityServices";
import Cloudconsultingmigration from "./dark/cloudconsultingmigration";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import Slideshow from "./dark/slideImages";

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <HeaderThree color="light" />
                <Switch>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Demo}/> */}                    
                    <Route exact path="/"  component={DarkPortfolioLanding}/> 
                    <Route  path="/about" component={About}/>
                    {/* <Route path="/services"  component={Services}/> */}
                    <Route  path="/telecom"  component={Telecom} />
                    {/* <Route  path="/enterprise"  component={Enterprise}/>*/}
                    <Route  path="/clients"  component={Clients}/>
                    <Route  path="/contact" component={Contact}/>
                    <Route path="/Verticals" component={Verticals}/>
                    
                    <Route  path="/ourservices" component={Ourservices}/>
                    <Route  path="/engagementmodels" component={Engagementmodels}/>
                    <Route path="/customsoftwareservice" component={Customsoftwareservice}/>
                    <Route path="/digitaltransservice" component={Digitaltransservice}/>
                    <Route path="/softproductdevp" component={Softproductdevp}/>
                    <Route path="/mobileappdev" component={Mobileappdev}/>
                    <Route path="/qatesting" component={Qatesting}/>
                    <Route path="/frontenddevp" component={Frontenddevp}/>
                    <Route path="/appmaintsupport" component={Appmaintsupport}/>
                    <Route path="/devopsservice" component={Devopsservice}/>
                    <Route path="/softDevStartups" component={SoftDevStartups}/>
                    <Route path="/softDevBFSI" component={SoftDevBFSI}/>
                    <Route path="/educationELearning" component={EducationELearning}/>
                    <Route path="/realEstate" component={RealEstate}/>
                     <Route path="/travelandHospitality" component={TravelandHospitality}/>
                    <Route path="/softwareSecurityServices" component={SoftwareSecurityServices}/>
                    <Route path="/cloudconsultingmigration" component={Cloudconsultingmigration}/>
                </Switch>
                <FooterTwo/>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();