import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import ContactButton from "../dark/contactbutton";
import './customStyles.scss';



const Appmaintsupport = () => {


    return (

        <div className=''>
            {/* Start Service Area  */}
            <div id="services" className="fix">
                <div className="service-area creative-service-wrapper pb--20 pt--70 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    
                                    {/*Section 1*/}
                                    <div className="titleMainheder">
                                        <h4 className="title mb-3">App Maintenance & Support</h4>
                                    </div>
                                    <div className=" px-3 paracenter ">
                                        
                                            <p className="para2 pt-2 ml-2">Outsourcing your application maintenance and support frees up your team to focus on
                                                business—critical initiatives. Our maintenance and support teams seamlessly blend with clients development and support practices ensuring smooth software operation and scalability.
                                            </p>
                                       
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end Section 1*/}
                {/* Start Section 2 */}
                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        
                                        <h4 className=' title1 mb-3 mt-2'> Application Maintenance Services</h4>
                                        <hr/>
                                    </div>
                                    <div className="row mt-5px-4">
                                        <div className="row section-title mb--20 mb_sm--0">
                                            <div className="col-sm px-4 border  bg-white text-dark">
                                                <h6 className=' head pt-1 text-center'>Corrective</h6>
                                                <p className="para2 ">We’ll identify and fix software design, logical and coding errors, bugs and defects to increase system stability, consistency of software operation and satisfaction of your customers.</p>
                                            </div>
                                            <div className="col-sm px-4 border  bg-white text-dark">
                                                <h6 className='head pt-1 text-center'>Adaptive</h6>
                                                <p className="para2 ">We’ll help you deliver value to your stakeholders and better compete on the market by developing and deploying new features, adapting software to changing business and Customer needs.</p>
                                            </div>
                                            <div className="col-sm px-4 border bg-white text-dark">
                                                <h6 className='head pt-1 text-center'>Perfective</h6>
                                                <p className="para2 ">We’ll re-engineer your application to adapt to changing user requirements, perform system functional enhancements, and work on increasing application performance and efficiency.</p>
                                            </div>
                                            <div className="col-sm px-4 border  bg-white text-dark">
                                                <h6 className='head pt-1 text-center'>Preventive</h6>
                                                <p className="para2 ">We’ll ensure your application is secure and stable, minimize system downtime and increase software maintainability by restructuring and optimizing the code, updating documentation and performing security audits.</p>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 2*/}
                {/* Start Section 3 */}
                <div className="service-area creative-service-wrapper ptb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        
                                        <h4 className=' title mb-3 mt-2'> Software Support Services</h4>
                                        <hr/>
                                    </div>
                                    <div className="row mt-5px-4">
                                        <div className="row section-title mb--20 mb_sm--0">
                                            <div className="col-sm px-4 border  btn-primary ">
                                                <h6 className='head pt-1 text-center'>Level 1 (L1)</h6>
                                                <p className="para1b ">Filters Help Desk calls, emails and provides basic support, real-time troubleshooting (e.g. password resets, break/fix instructions), ticket routing and escalation to Level 2 and Level 3 support. L1 will get outside vendor assistance and maintenance when needed. Level 1 personnel gather and analyze information about the users’ issues determining the best way to resolve the problem. Level 1 also provides support for configuration solutions that were resolved and documented previously by Level 2 and Level 3 engineers.</p>
                                            </div>
                                            <div className="col-sm px-4 border btn-primary ">
                                                <h6 className='head pt-1 text-center'>Level 2 (L2)</h6>
                                                <p className="para1b ">Experienced and knowledgeable technical specialists that assist Level 1 personnel with resolution of technical problems. They investigate the raised issues and check for known solutions to complex issues. These engineers are especially knowledgeable in resolution and prevention of general IT infrastructure issues (troubleshooting, configuration, database administration). They also work with Level 3 engineers providing support in complex cases. L2 engineers handle issues escalated from L1. Level 2 will escalate to Level 3, when necessary.</p>
                                            </div>
                                            <div className="col-sm px-4 border  btn-primary ">
                                                <h6 className='head pt-1 text-center'>Level 3 (L3)</h6>
                                                <p className="para1b ">These are our expert Operations and CPEspecialists handling the most complicated problems. L3 engineers are assisting both L1 and L2 engineers with complex, multifaceted problems as well as high severity critical issues requiring immediate resolution. They also research and develop solutions for new issues.
                                                    
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 3*/}
                 {/*Section 4*/}

                 <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>

                                    <div className="text-center1">
                                    
                                    <h4 className=' title1 mb-3 mt-2'> 24x7 System Monitoring</h4>
                                    <hr/>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-5 ml-3 ">
                                        <p className="para1b ">Indibridge offers 24/7 maintenance, support and monitoring of your core applications, hardware and overall system performance to ensure system uptime. The support team is immediately alerted when your system crosses the defined thresholds to avoid your server going down, memory reaching capacity, or a workstation consistently being overloaded.
                                                </p>
                                        </div>
                                        <div className="col-sm-6  ml-3  ">
                                           
                                            <ul className='listalign'>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Application monitoring</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Server monitoring</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Network and remote connectivity management</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Event log management</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Backup & recovery</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 4*/}

            </div >
            <div div className='p-3' > <ContactButton /></div>
        </div >
    )
}

export default Appmaintsupport;

