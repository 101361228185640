import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import ContactButton from "../dark/contactbutton";
import './customStyles.scss';



const Mobileappdev = () => {


    return (

        <div className=''>
            {/* Start Service Area  */}
            <div id="services" className="fix">
                <div className="service-area creative-service-wrapper pb--20 pt--70 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    
                                    {/*Section 1*/}
                                    <div className="titleMainheder">

                                        <h4 className="title mb-3">Mobile App Development</h4>
                                    </div>
                                    <div className="px-3 paracenter ">

                                        <p className="para2">We are pushing the boundaries of what’s possible on mobile by creating transformative mobile experiences that solve business challenges for clients ranging from large enterprises to innovative startups and creative entrepreneurs. With in-depth knowledge of native iOS, Android and cross-platform application development, we make sure that your idea is transformed into a beautifully designed, user-friendly and engaging mobile solution of a superior quality.</p>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end Section 1*/}
                {/*Section 2*/}

                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>

                                    <div className="text-center1">

                                        <h4 className=' title1 mb-3 mt-2'> Mobile App Development Services</h4>
                                        <hr />
                                    </div>
                                    <div className="row2 px-4">
                                        <div className="col-sm-5 ">
                                            <ul className='listalign'>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Native iOS mobile app development</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Native Android mobile app development</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Cross-Platform mobile solutions</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Responsive HTML5 mobile applications</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Hybrid app development</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-6 ">
                                            <h5 className='headbgb'>As a full-cycle mobile app development company, we offer</h5>
                                            <ul className='listalign'>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Research, Discovery and Strategy services</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>UX/UI Design</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Rapid Prototyping and Proof-of-Concept applications</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Greenfield mobile app development</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Mobile application migration and optimization</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Legacy application modernization</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Quality assurance, Testing and test Automation</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Maintenance and Support</li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 2*/}
                {/*  Section 3*/}
                <div className="service-area creative-service-wrapper ptb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>

                                    <div className="titleMainheder">
                                        <h4 className=' title mb-3 mt-2'> Custom IPhone and IPad Application Development</h4>
                                        <hr />
                                    </div>
                                    <div className="row px-2">
                                        <div className="col-sm-7 ml-1 ">
                                            <p className="para2">We excelled at developing cutting-edge iPhone and iPad applications that are tailored to suit client requirements. We have worked with industry specific solutions as well as customized apps for iPhone. Our iPhone software development teams are experts in a variety of areas including finance applications, telecom, educational applications.</p>

                                        
                                        <div className="">
                                            <h5 className='head mb-3'>iPhone and iPad Development services includes</h5>

                                            <ul className='listalign'>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>Greenfield iOS development for iPhone, iPad, Apple Watch and IoT devices</li>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>Existing mobile app upgrades and optimization</li>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>iOS enterprise mobile application development</li>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>Desktop application migration and adaptation for iOS-enabled mobile devices</li>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>Responsive UX/UI Design services</li>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>Server-side APIs</li>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>Mobile quality assurance, testing and test automation</li>
                                            </ul>
                                        </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-4  ">
                                            <img className="CustomIPhoneIPad " src="./assets/images/bg/CustomIPhoneIPad.jpg" alt="CustomIPhoneIPadApplicationDevelopment Images" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 3*/}
                {/*Section 4*/}

                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>

                                    <div className="titleMainheder">

                                        <h4 className=' title1 mb-3 mr-4 '> Custom Android Application Development</h4>
                                        <hr />
                                    </div>

                                    <div className="row px-2 float-right">
                                    <div className="col-sm-6 col-md-5 col-lg-5  ">
                                            <img className="CustomAndroid " src="./assets/images/bg/CustomAndroidApplicationDevelopment.jpg" alt="CustomAndroidApplicationDevelopment Images" />
                                        </div><br />
                                        <div className="col-sm-6 px-4 float-right ">
                                            <p className="para1b">Indibridge has experience of working with a diverse clientele in Android application development ranging from aspiring entrepreneurs, early-stage ventures to large enterprise enterprises. Our Android app development teams follow industry standards paired with agile processes, adopt best application development practices and proven methodologies. Utilizing myriad of Android development tools along with harnessing maximum potential of Android SDK, our Android developers ensure successful completing of any project within client development timelines.</p>


                                            <div className=" px-4  ">
                                                <h5 className='headbgb mb-3'>Custom Android development services includes</h5>
                                                <ul className='listalign'>
                                                    <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Greenfield Android development for phones, tablets, wearable and IoT devices</li>
                                                    <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Existing mobile app upgrades and optimization</li>
                                                    <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Android and cross-platform enterprise mobile application development</li>
                                                    <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Responsive UX/UI Design services</li>
                                                    <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Android Widget development</li>
                                                    <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Mobile quality assurance, testing and test automation</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 4*/}

                {/* Start Section 5 */}
                <div className="service-area creative-service-wrapper ptb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>

                                    <div className="text-center1">
                                        <h4 className=' title mb-3 mt-2'> Mobile Application Development Expertise</h4>
                                        <hr />

                                    </div>
                                    <div className="rowpx-4">
                                        <div className="col-sm- px-4 ">
                                            <ul className='listalign'>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>Conferencing and video/audio calling applications</li>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>Games</li>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>IoT and wearables</li>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>mCommerce</li>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>Finance apps</li>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>mHealth and Telemedicine</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm px-4 ">

                                            <ul className='listalign'>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>Social Media and Marketing apps</li>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>VR and AR applications</li>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>Mobile CMS</li>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>Mobile analytics application</li>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>BYOD implementation</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* End Section 4*/}
            </div >
            <div div className='p-3' > <ContactButton /></div>
        </div >
    )
}

export default Mobileappdev;

