import React, { Component } from "react";
import App from './registerForm';
import emailjs from 'emailjs-com';
import swal from 'sweetalert';

class ContactThree extends Component{
    constructor(props){
        super(props);
        this.state = {
            name: '',
            mobileNumber: '',
            email: '',
            message: '',
            showSuccessMsg:false,
          
        };
        this.sendEmail = this.sendEmail.bind(this)
        this.handleChange=this.handleChange.bind(this)
    }
     handleChange = (event) => {
        
        this.setState({
            [event.target.name]: event.target.value
        });

      };
     sendEmail(e) {
        
        e.preventDefault();
       
        if(this.state.email ==''){
            swal("Error!", "Email Should Not Be Empty!");
        }
        else if(this.state.name ==''){
            swal("Error!", "Name Should Not Be Empty!");
        }
        else if(this.state.mobileNumber ==''){
            swal("Error!", "MobileNumber Should Not Be Empty!");
        }
        else if(this.state.message ==''){
            swal("Error!", "Message Should Not Be Empty!");
        }
        else{
           // if(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/.test(this.state.email)){
                const saveEmail = {
                    email: this.state.email,
                    name: this.state.name,
                    mobileNumber: this.state.mobileNumber,
                    eventDate: this.state.message
                  }
                  emailjs.sendForm('service_edmcens', 'template_j7bg8lv', e.target, 'S_O2Ryp18nHqFro5w')
            .then((result) => {
                swal("Good job!", "Email Sent Successfully!", "success");
                window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
               swal("Error!", "Server issue!");
            });
            // }
            // else{
            //     swal("Error!", "this Email Is Not Valid!");
            // }
            
        }
    }
      

    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--20">
                                {/* <span className="subtitle">Let's Say Hi</span> */}
                                <h2 className="title">Connect With Us</h2>
                                <div className="im_address_inner">
                                    <div className="im_address">
                                        <span><b>Call us directly:</b></span>
                                        <a className="link im-hover" href="tel:+91 8591222022<">+91 8591222022</a>
                                    </div>
                                    <div className="im_address mt--5">
                                        <span><b>Contact Email:</b></span>
                                        <a className="link im-hover" href="mailto:hello@indibridge.com">hello@indibridge.com</a>
                                    </div>
                                </div>
                            </div>
                                <div className="form-wrapper">
         <form  onSubmit={this.sendEmail}>
        <label htmlFor="item01"> 
            <input
                type="text"
                name="name"
                id="item01"
                placeholder="Your Name *"
                onChange={this.handleChange}
               value={this.state.name}
                
            />
        </label>

        <label htmlFor="item02">
            <input
                type="number"
                name="mobileNumber"
                id="item02"
                placeholder="Mobile no *"
                onChange={this.handleChange}
               value={this.state.mobileNumber}
                
            />
        </label>
        <label htmlFor="item03">
            <input
                type="text"
                name="email"
                id="item03"
                placeholder="Your email *"
                onChange={this.handleChange}
               value={this.state.email}
                
            />
        </label> 
        <label htmlFor="item04">
            <textarea
                type="text"
                id="item04"
                name="message"                
                placeholder="Your requirement description"
                onChange={this.handleChange}
               value={this.state.message}
               
            />
        </label> 
        <button className="btn-default"  type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit</button>
    </form>
                                    {/* {this.state.showSuccessMsg && <div className="mb--20" style={{color: '#155724', backgroundColor: '#d4edda', borderColor: '#c3e6cb', fontSize: '14px', padding: "10px", borderRadius: '4px'}}>We have received your pre-registration request successfully!!</div>}
                                    <App/> */}
                                </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src={`${this.props.contactImages}`} alt="trydo"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactThree; 