import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import ContactButton from "../dark/contactbutton";
import './customStyles.scss';



const TravelandHospitality = () => {


    return (

        <div className=''>
            {/* Start Service Area  */}
            <div id="services" className="fix">
                <div className="service-area creative-service-wrapper pb--20 pt--70 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>
                                    {/*Section 1*/}
                                    <div className="titleMainheder">
                                        <h4 className="title mb-3">Travel & Hospitality</h4>
                                    </div>
                                    <div className='px-3 paracenter'>
                                        <div>
                                            <p className="para2 pt-2">Indibridges Travel and Hospitality full-cycle Software Development Services are designed to create stunning personalized experiences that modern travellers seek.</p>
                                            <p className="para2 pt-2">We help develop and maintain modern scalable travel software solutions, web and mobile
                                            applications customized to unique clients’ business needs. We work with travel agents and operators, hotels, restaurants, travel portals and booking websites, and aviation companies to transform the way people travel in a modern world.
                                        </p>
                                        </div>
                                    </div>
    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end Section 1*/}
                {/*Section 2*/}

                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">

  
                                        <h4 className=' title1 mb-3 mt-2'> Travel Software Development Expertise</h4>
                                        <hr/>
                                    </div>
                                    <div className="rowpx-4">
                                        <div className="col-sm-5 ">
                                            <ul className='listalign'>
                                            <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>TRAVEL MOBILE APPS</li>
                                            <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>FLIGHT MANAGEMENT BACK-OFFICE SOFTWARE</li>
                                            <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>FLIGHT BOOKING SOFTWARE</li>
                                            <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>HOTEL BOOKING APPLICATIONS</li>
                                            <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>TRAVEL PORTALS</li>
                                            <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>RECOMMENDATION ENGINES</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-5  ">
                                            <ul className='listalign'>
                                            <li  className='text-white'><i class="bi bi-check-circle-fill px-2"></i>TRAVEL AGENCY SOFTWARE</li>
                                            <li  className='text-white'><i class="bi bi-check-circle-fill px-2"></i>RESTAURANT DYNAMIC MENUS</li>
                                            <li  className='text-white'><i class="bi bi-check-circle-fill px-2"></i>REVENUE MANAGEMENT</li>
                                            <li  className='text-white'><i class="bi bi-check-circle-fill px-2"></i>LOYALTY PROGRAMS</li>
                                            <li  className='text-white'><i class="bi bi-check-circle-fill px-2"></i>SMART SEARCH ENGINES</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 2*/}
                {/*  Section 3*/}
                <div className="service-area creative-service-wrapper ptb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">

                 
                                        <h4 className=' title mb-3 mt-2'> Services</h4>
                                        <hr/>
                                    </div>
                                    <div className="rowpx-4">
                                        <div className="col-sm-5 ">
                                            <ul className='listalign'>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Web Development</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Solutions Architecture</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Travel Mobile App Development</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Website UX and UI Services</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Big Data and Analytics Solutions</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Cloud Engineering</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-5  ">
                                        <ul className='listalign'>
                                        <li><i class="bi bi-check-circle-fill px-2"></i>Machine Learning and AI Implementation</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>System Integration</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Quality Assurance</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Travel Software Maintenance and Support</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>IoT integration and solutions</li>
                                            </ul>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 3*/}
                
            </div >
            <div div className='p-3' > <ContactButton /></div>
        </div >
    )
}

export default TravelandHospitality;

