import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import ContactButton from "../dark/contactbutton";
import './customStyles.scss';



const Customsoftwareservice = () => {


    return (

        <div className=''>
            {/* Start Service Area  */}
            <div id="services" className="fix">
                <div className="service-area creative-service-wrapper pt--70 pb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>
                                    {/*Section 1*/}
                                    <div className="titleMainheder">
                                        <h4 className="title  mb-3">Custom Software Development</h4>
                                    </div>


                                    <div className="row  paracenter">
                                        <div className=" ">
                                            <p className="para2 pt-2 ">Indibridge is a premier full-service Custom Software Development, Cloud Engineering, QA and DevOps company, specializing in the development of time-sensitive and innovative solutions. To every project indibridge brings a combination of domain expertise, exceptional engineering talent, rigorous Agile development processes, commitment to protection of client’s IP and accountability.</p>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end Section 1*/}
                {/*Section 2*/}

                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>

                                    <div className="titleMainheder">
                                        <h5 className='title1 mt-2 mb-3'> Software Development Services We Offer</h5>
                                        <hr />
                                    </div><br />
                                    <div className="row px-4">
                                        <div className="col-sm  ">
                                            <ul className='listalign'>
                                                <li className='text-white'><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Discovery and Analysis</li>
                                                <li className='text-white'><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Architecture and UX/UI design</li>
                                                <li className='text-white'><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>POC and MVP development</li>
                                                <li className='text-white'><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Custom Software Development</li>

                                            </ul>
                                        </div>
                                        <div className="col-sm  ">
                                            <ul className='listalign'>

                                                <li className='text-white'><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>QA Testing and Automation</li>
                                                <li className='text-white'><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>DevOps Implementation</li>
                                                <li className='text-white'><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Cloud Migration, Optimization and Maintenance</li>
                                                <li className='text-white'><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Support and Maintenance</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 2*/}
                {/*  Section 3*/}
                <div className="service-area creative-service-wrapper ptb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div >
                                        <div className="titleMainheder">
                                            <h4 className='title mb-3 mt-2 pr-5 '> Digital Transformation Services</h4>
                                            <hr />      
                                        </div>
                                        
                                    </div>


                                    <div className="row">

                                        <div className="col-sm-6 col-md-5 col-lg-6 px-4 ">
                                            <p className="para2 mt-5">We draw on our years of experience modernizing legacy systems and developing innovative scalable custom software solutions from scratch, our top-notch cloud engineering and custom software development talent, breadth of technical expertise and extensive capabilities in help our clients reinvent and adapt their software solutions and applications to drive competitive advantage, create customer value, business agility and innovation in the modern age. We promptly act on your Vision, and realize it in a Secure, Reliable and Scalable way.</p>
                                        </div>
                                        <div className="col-sm-6 col-md-5 col-lg-6  ">
                                            <img className="imgdigital " src="./assets/images/bg/DigitalTransformationServices.jpg" alt="Digital Images" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 3*/}

                {/* Start Section 4*/}
                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="titlecenter">
                                        <h4 className='title1 mb-3 mt-2 pr-5 '> <b> Devops-as-a-Service</b></h4>
                                        <hr />
                                    </div >

                                    <div className="row float-right ">

                                        <div className="col-sm col-md-5 col-lg-5 ">
                                            <img className="Devopsimg" src="./assets/images/bg/Devops-as-a-Service.jpg" alt="Devops Images" />
                                        </div>
                                        <div className="col-sm-6 px-4 float-right ">

                                            <div className=" devsermt">
                                                <p className="para1b devser">Indibridge’s customized DevOps services enable clients’ business agility and ability to promptly and sustainably respond to market changes. We unify software development and operations into an efficient and rapid deployment infrastructure by providing our clients with a solid plan for a painless and predictable move to DevOps practices tailored to clients’ release schedules, end-to-end software delivery automation with scalability and security in mind, transition to and implementation of CI/CD, and seamlessly working automated software delivery pipeline.</p>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 4*/}
            </div >
            <div div className='p-3' > <ContactButton /></div>
        </div >
    )
}

export default Customsoftwareservice;
