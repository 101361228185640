import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import ContactButton from "../dark/contactbutton";
import './customStyles.scss';



const Softproductdevp = () => {


    return (

        <div className=''>
            {/* Start Service Area  */}
            <div id="services" className="fix">
                <div className="service-area creative-service-wrapper pb--20 pt--70 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>
                                    {/*Section 1*/}
                                    <div className="titleMainheder">
                                        <h4 className=" title mb-3 mt-2">Software Product Development</h4>
                                    </div>
                                    <h5 className='head text-center'>ACCELERATE TIME TO MARKET WHILE REDUCING DEVELOPMENT COSTS</h5>
                                    <div className=" px-3 paracenter">
                                        <div>

                                            <p className='para2'>While you build trust and credibility as a company, Indibridge will back you up with reliability and agility on the technical side reducing time to market and associated risks. We have years of experience and impeccable reputation helping companies ranging from startups to large enterprises transform product roadmap into stable, scalable and secure state-of-the-art market-ready solutions.</p>
                                            <p className='para2'>We work exclusively with the companies based on the timeliness and the quality of their software products. Not only do we bring a breadth of technical expertise and resources to match any technical need, but also a reliability and commitment to business agility with seamless team integration and rapid knowledge transfer.</p>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end Section 1*/}
                {/*Section 2*/}

                <div className="service-area creative-service-wrapper pt--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>

                                    <div className="text-center1">
                                        <h4 className=' title1 mb-3 mt-2'> Products We Develop</h4>
                                        <hr />
                                    </div><br />

                                    <p1 className='para1b'>In indibridge’s experience of delivering state-of-the-art solutions we have helped our clients to nurture their products from an idea to market success. We have expertise working with companies across a wide range of industries helping to develop products like:</p1>
                                    <br /><br />
                                    <ul className='listalign'>
                                        <li className='text-white'><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Software-as-a-Service Solutions (SaaS)</li>
                                        <li className='text-white'><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Mobile Application</li>
                                        <li className='text-white'><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Desktop Solutions</li>
                                        <li className='text-white'><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Data Platforms</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 2*/}
                {/*  Section 3*/}
                <div className="service-area creative-service-wrapper pt--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>
                                    {/*Digital Transformation Services*/}
                                    <div className="titleMainheder">

                                        <h4 className=" title mb-3 mt-2"> Product Development Services</h4>
                                        <hr />

                                    </div>



                                    <div className="row px-4">
                                        <div className="col-sm-6 ml-1 ">
                                            <p className='para2'>Indibridge is a full-service application development partner specializing in the development, enhancement and maintenance of time-sensitive solutions. We can enter and exit at any stage of the application lifecycle rapidly delivering value through our rigorous training and
                                                integration processes, top-notch engineering resources and commitment to clients’ goals and business objectives. We ensure fast time-to-market without sacrificing product quality and security.
                                            </p><br />
                                         
                                            <ul className='listalign'>
                                                <li><div className="menu"><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Product Strategy and Alignment</div></li>
                                                <li><div className="menu"><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Roadmap Assessment</div></li>
                                                <li><div className="menu"><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Architecture Design and Development</div></li>
                                                <li><div className="menu"><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>User Experience and UI Implementation</div></li>
                                                <li><div className="menu"><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Ongoing Product Development</div></li>
                                                <li><div className="menu"><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Greenfield Cloud-Native Product Development</div></li>
                                                <li><div className="menu"><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Quality Assurance, Testing and Test Automation</div></li>
                                                <li><div className="menu"><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Application Support and Maintenance</div></li>
                                            </ul>
                                           
                                        </div>
                                        <div className="col-sm-6 col-md-5 col-lg-5  ">
                                            <img className="ProductDev " src="./assets/images/bg/ProductDevelopmentServices.jpg" alt="ProductDevelopmentServices Images" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 3*/}

                {/* Start Section 4
                <div className="service-area creative-service-wrapper ptb--10 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        <h5 className='head mt-3'> Agile DevOps Services:</h5>
                                    </div>
                                    <div id="dts">
                                        <div>
                                            <p className='para pt-2'>Digital Transformation is hard to achieve without a solid infrastructure automation foundation and close collaboration between teams. Pairing Cloud with DevOps allows organizations to speed the pace of innovation and better respond to continuous change in market conditions and evolving customer demands. DevOps helps to enable rapid prototyping and faster, more stable software and services delivery.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 4*/}
            </div >
            <div div className='p-3' > <ContactButton /></div>
        </div >
    )
}

export default Softproductdevp;
