import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import './customStyles.scss';



const SlideList = [
    {
        textPosition: 'text-left',
        category: 'connecting value',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]
const PortfolioLanding = () => {
    let title = 'Welcome to Indibridge',
        description = `TALK TO US FOR BESPOKE TELECOM AND IOT <b>SOLUTIONS.`;
    return (
        <div className="active-dark bg_color--9">
            <Helmet pageTitle="Indibridge" />

            

            {/* Start Slider Area   */}
            <div id="home" className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value, index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                       <div className={`inner ${""}`}><br/>
                                            {/* {value.category ? <span style={{ color: '#000000' }}>{'category.value'}</span> : ''} */}
                                            <h2 className="title ">Accelerating your Digital Transformation Journey<br />
                                                {/*<TextLoop>
                                                    <span style={{ textShadow: '1px 1px #808080',color: '#FF0000' }}>Telecom</span>
                                                    <span style={{ textShadow: '1px 1px #808080',color: '#FF0000' }}>Enterprise</span>
                                                </TextLoop>{" "}*/}
                                            </h2>
                                            {/* <h2></h2> 
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn mt--30"><a className="btn-default" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            
            {/* End Slider Area   

            <FooterTwo />
            {/* Start Back To Top 
            <div className="backto-top">
                <ScrollToTop style={{ bottom: '120px' }} showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            

        </div>
        
    )
}

export default PortfolioLanding;
