import React, { Component } from "react";
import { FaLinkedinIn,FaWhatsappSquare } from "react-icons/fa";
import { FiX, FiMenu } from "react-icons/fi";

import Scrollspy from 'react-scrollspy';
import './dropdown.scss';




const SocialShare = [
    { Social: <FaLinkedinIn color="#0000FF" size="30px" />, link: 'https://www.linkedin.com/company/indibridge/' },
    { Social: <FaWhatsappSquare color="#008000" size="30px"/>,link: 'https://wa.me/8591222022' }, //link: 'https://web.whatsapp.com/' },
]
class HeaderThree extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

        this.serviceTrigger = this.serviceTrigger.bind(this);
        this.verticalTrigger = this.verticalTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded');
        })

    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader() { }

    serviceTrigger() {

        document.querySelector('.service-menu').classList.add('sub-menu-show');


    }
    verticalTrigger() {

        document.querySelector('.vertical-menu').classList.add('sub-menu-show');

    }

    render() {

        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const { logo, color = 'default-color' } = this.props;
        let logoUrl;
        if (logo === 'light') {
            logoUrl = <a href="/"> <img src="./assets/images/logo/Indibridge_logo2.png" alt="" /> </a>;
        } else if (logo === 'dark') {
            logoUrl = <a href="/"> <img src="./assets/images/logo/Indibridge_logo2.png" alt="" /> </a>;
        } else if (logo === 'symbol-dark') {
            logoUrl = <a href="/"> <img src="./assets/images/logo/Indibridge_logo2.png" alt="" /> </a>;
        } else if (logo === 'symbol-light') {
            logoUrl = <a href="/"> <img src="./assets/images/logo/Indibridge_logo2.png" alt="" /> </a>;
        } else {
            logoUrl = <a href="/"><img src="./assets/images/logo/Indibridge_logo2.png" alt="" /></a>;
        }

        return (
            <header className={`header-area header-style-two header--fixed sticky ${color}`}>
                <div className="header-wrapper">
                    <div className="header-left d-flex align-items-center">
                        <div className="logo">
                            <a href={this.props.homeLink}>
                                {logoUrl}
                            </a>
                        </div>
                        <nav className="mainmenunav d-lg-block ml--50">
                            <Scrollspy className="mainmenu" items={['home', 'about', 'services', 'verticals', 'clients', 'contact']} currentClassName="current" offset={-200}>
                                <li><a href={`${process.env.PUBLIC_URL}/`} >Home</a></li>
                                <li ><a href={`${process.env.PUBLIC_URL}/about`} >About</a>
                                </li>
                                <li><a href="javascript:void(0)" onClick={this.serviceTrigger}>Services</a>
                                    <ul className="submenu service-menu">
                                        <li><a href={`${process.env.PUBLIC_URL}/customsoftwareservice`} >Custom Software Development</a>
                                        </li>
                                        <li> <a href={`${process.env.PUBLIC_URL}/digitaltransservice`} >Digital Transformation</a>
                                        </li>
                                        <li><a href={`${process.env.PUBLIC_URL}/softproductdevp`} >Software Product Development </a>
                                        </li>
                                        <li><a href={`${process.env.PUBLIC_URL}/cloudconsultingmigration`} >Cloud Consulting & Migration</a>
                                        </li>
                                        <li><a href={`${process.env.PUBLIC_URL}/mobileappdev`} >Mobile App Development </a>
                                        </li>
                                        <li><a href={`${process.env.PUBLIC_URL}/qatesting`} >Software QA & Testing</a>
                                        </li>
                                        <li><a href={`${process.env.PUBLIC_URL}/frontenddevp`} >Frontend Development UI UX Design</a>
                                        </li>
                                        <li><a href={`${process.env.PUBLIC_URL}/appmaintsupport`} >App Maintenance & Support</a>
                                        </li>
                                        <li><a href={`${process.env.PUBLIC_URL}/devopsservice`} >DevOps</a>
                                        </li>
                                    </ul>
                                </li>
                                <li><a href="javascript:void(0)" onClick={this.verticalTrigger} >Verticals </a>
                                    <ul className="submenu vertical-menu">
                                        <li><a href={`${process.env.PUBLIC_URL}/telecom`} >Telecom</a>
                                        </li>
                                        <li><a href={`${process.env.PUBLIC_URL}/softDevStartups`} >Software Development  for Startups</a>
                                        </li>
                                        <li> <a href={`${process.env.PUBLIC_URL}/softDevBFSI`} >Software Development  for BFSI Sector </a>
                                        </li>
                                        <li><a href={`${process.env.PUBLIC_URL}/educationELearning`} >Education & e-Learning </a>
                                        </li>
                                        <li><a href={`${process.env.PUBLIC_URL}/realEstate`} >Real Estate </a>
                                        </li>
                                        <li><a href={`${process.env.PUBLIC_URL}/travelandHospitality`} >Travel & Hospitality</a>
                                        </li>
                                        <li><a href={`${process.env.PUBLIC_URL}/softwareSecurityServices`} >Software Security</a>
                                        </li>
                                    </ul>
                                </li>
                                <li><a href={`${process.env.PUBLIC_URL}/clients`} >Clients</a></li>
                                <li><a href={`${process.env.PUBLIC_URL}/contact`} >Connect</a></li>



                            </Scrollspy>
                        </nav>

                    </div>
                   
                    <div className="header-right">
                         {/**start SocialShare */}
                      <div className="social-share-inner d-sm-block">
                            <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                                {SocialShare.map((val , i) => (
                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul>
                        </div> 
                         {/**End SocialShare */}

                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                    
                </div>
            </header>
        )
    }
}
export default HeaderThree;