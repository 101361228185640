import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import ContactButton from "../dark/contactbutton";
import './customStyles.scss';



const Frontenddevp = () => {


    return (

        <div className=''>
            {/* Start Service Area  */}
            <div id="services" className="fix">
                <div className="service-area creative-service-wrapper pt--70 pb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>
                                    {/*Section 1*/}
                                    <div className="titleMainheder">
                                        <h4 className="title mb-3">Frontend Dev, UI/UX Design</h4>
                                    </div>
                                    <div className=" px-3 paracenter ">
                                       
                                            <p className="para2 pt-2">We blend skill and expertise to produce elegant, functional and user-focused interfaces. We let the purpose of the particular web or mobile application drive its design and user experience to deliver high user acceptance and engagement rates of your product.</p>
                                        
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end Section 1*/}
                {/*Section 2*/}

                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                    <h4 className=' title1 mb-3 mt-2'> Front-End Development Services</h4>
                                        <hr/>
                                    </div>

                                    
                                    <div className="row1 px-4">
                                        <div className=" ml-1 ">
                                       
                                        
                                            <ul className='listalign'>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Front-End Architecture and Design</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Rapid Prototyping</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Front-end assessment and optimization services</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Front-end Migration</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Front-end Quality assurance services</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Maintenance and Support</li>
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                    <h4 className='title1 mb-3 mt-2'>UX/UI Design Services</h4>
                                            <hr/>
                                    </div>

                                    
                                    <div className="row1 px-4">
                                       
                                        <div className="ml-1">
                                            
                                            <ul className='listalign'>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>UX/UI Audit and Analysis</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>User Interface Wireframing</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Clickable Prototypes</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>User Journey mapping and design</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Cross-Device User Experience</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Mobile UX/UI Design Services</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Cross-device Responsive design</li>
                                               

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 2*/}
                {/*  Section 3*/}
                <div className="service-area creative-service-wrapper ptb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>

                                    <div className="text-center1">
                                      
                                        <h4 className=' title mb-3 mt-2'> We Leverage The Lasted Tech</h4>
                                        <hr/>
                                    </div>
                                    <div class="mt-5 px-1 text-center">
                                        <div class="row">
                                            <div class="col-sm">
                                                <img className="realestateimg " src="./assets/images/logo/React.png" alt="Cloud Migration Images" /><br />
                                                <h6 className=" mt-3 pb-2">React JS</h6>
                                            </div>
                                            <div class="col-sm">
                                                <img className="realestateimg" src="./assets/images/logo/Node.png" alt="Cloud Migration Images" /><br />
                                                <h6 className=" mt-3 pb-2">Node JS</h6>
                                            </div>
                                            <div class="col-sm">
                                                <img className="realestateimg" src="./assets/images/logo/Bootstrap.png" alt="Bootstrap Images" /><br />
                                                <h6 className=" mt-3 pb-2">Bootstrap</h6>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="mt-1 px-1 text-center">
                                        <div class="row">
                                            <div class="col-sm">
                                                <img className="realestateimg " src="./assets/images/logo/Angular.png" alt="Angular Images" /><br />
                                                <h6 className=" mt-3 pb-2">Angular JS</h6>
                                            </div>
                                            <div class="col-sm">
                                                <img className="realestateimg" src="./assets/images/logo/CSS3.png" alt="CSS 3 Images" /><br />
                                                <h6 className=" mt-3 pb-2">CSS 3</h6>
                                            </div>
                                            <div class="col-sm">
                                                <img className="realestateimg" src="./assets/images/logo/JavaScript.png" alt="JavaScript Images" /><br />
                                                <h6 className=" mt-3 pb-2">JavaScript</h6>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 3*/}
                
               
            </div >
            <div div className='p-3' > <ContactButton /></div>
        </div >
    )
}

export default Frontenddevp;

