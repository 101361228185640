import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import { Link, Button } from 'react-router-dom';
import './customStyles.scss';

const ContactButton = () => {
    return (
        <div className='text-center col-15'>
            <Link to="/contact"> 
                <button className='btn-default' data-toggle="button"   >Contact Us to Get Started</button>
            </Link> 
        </div>

    )
}

export default ContactButton;