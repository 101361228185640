import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import ContactButton from "../dark/contactbutton";
import './customStyles.scss';



const Qatesting = () => {


    return (

        <div className=''>
            {/* Start Service Area  */}
            <div id="services" className="fix">
                <div className="service-area creative-service-wrapper pb--20 pt--70 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>
                                    {/*Section 1*/}
                                    <div className="titleMainheder">
                                        <h4 className="title mb-3">Software QA & Testing</h4>
                                    </div>
                                    <div className=" px-3 paracenter">

                                        <p className="para2">Indibridge has utilized an Integrated Quality Assurance practice to ensure the highest quality of our deliverables. We have perfected QA and Testing strategy and methodology that blend seamlessly into the development lifecycle without crossing the important boundary between the development and software quality assurance processes.</p>

                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end Section 1*/}
                {/*Section 2*/}

                <div className="service-area creative-service-wrapper pt--20 pb--10 bg_color--8 text-white">
                    <div className="container">


                        <div className="section-title mb--30 mb_sm--0">
                            <div className="text-center1">
                                {/*<h2 className="title">Services</h2>*/}
                            </div>

                            <div className="text-center1">

                                <h4 className=' title1 mb-3 mt-2'> Software QA and Testing Services</h4>
                                <hr/>
                            </div>

                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">



                                        <div className="rowpx-4">
                                        <div className="row section-title mb--20 mb_sm--0">

                                            <div className="col-sm px-4">

                                                <h5 className='text-white displayContent'><i class="bi bi-check-circle-fill px-2"></i>Consulting</h5>

                                                <p className="para1b">We work with you to assess your current Quality Assurance processes and help you define and build the best QA strategy with the right frameworks and tools.</p>
                                            </div>
                                            <div className="col-sm px-4  ">

                                                <h5 className='text-white displayContent'><i class="bi bi-check-circle-fill px-2"></i>Embedded Agile</h5>

                                                <p className="para1b"> The agile development methodology is an integral part of our approach. Our QA engineers, embedded as part of your teams, help you produce quality results on a rapid, regular basis. We work closely with Scrum Masters and the development team to deliver well-tested, functionally-complete products in every agile sprint or cycle.</p>
                                            </div>
                                            </div>

                                        </div>
                                        <div className="rowpx-4">
                                            <div className="row section-title mb--20 mb_sm--0">
                                                <div className="col-sm px-4 ">

                                                    <h5 className='text-white displayContent'><i class="bi bi-check-circle-fill px-2"></i>Complete Lifecycle</h5>

                                                    <p className="para1b">The goal of an effective QA process is to quickly identify and correct defects. We will help you define your test plan for both manual and automated testing across the entire software development lifecycle: from requirements through design, development, deployment and maintenance.</p>
                                                </div>
                                                <div className="col-sm px-4  ">

                                                    <h5 className='text-white displayContent'><i class="bi bi-check-circle-fill px-2"></i>Unit Testing Automation Platform</h5>

                                                    <p className="para1b">In the age of Agile Development and Continuous Integration it has become important for developers to write and integrate Unit Tests into their coding workflow. Indibridge works with your development team to create and integrate the Unit Test Execution Bench that minimizes the extra efforts for developers to use unit testing without decreasing the power of unit tests.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rowpx-4">
                                            <div className="row section-title mb--20 mb_sm--0">
                                                <div className="col-sm px-4 ">

                                                    <h5 className='text-white displayContent'><i class="bi bi-check-circle-fill px-2"></i>Custom Teams</h5>

                                                    <p className="para1b">Rapidly get a team that feels like an extension of your own. We work with you to quickly get the right team in place for your short- and long-term testing needs.</p>
                                                </div>
                                                <div className="col-sm px-4  ">

                                                    <h5 className='text-white displayContent'><i class="bi bi-check-circle-fill px-2"></i>Test Automation</h5>

                                                    <p className="para1b">We help you identify the best areas for testing automation and develop the scripts to enable and automate a script execution platform. We work with you to choose the right frameworks and tools to enable your QA strategy and to embed it in your development lifecycle.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rowpx-4">
                                            <div className="row section-title mb--20 mb_sm--0">
                                                <div className="col-sm px-4 ">
                                                    <h5 className='ultext text-white displayContent'><i class="bi bi-check-circle-fill px-2"></i>Web, Mobile, On-premises Applications</h5>

                                                    <p className="para1b">We are skilled in defining, executing and automating testing for Web, SaaS, Cloud online; Native, Hybrid and Responsive mobile; Stand-alone and Client/Server on-premises. We help you deliver impeccable quality, scalable performance and outstanding UI on any platform.</p>

                                                </div>
                                                <div className="col-sm px-4">
                                                  
                                                        
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div>
                    </div>
                </div>
                {/* End Section 2*/}

                {/* Start Section 3 */}
                <div className="service-area creative-service-wrapper pt--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        <h4 className=' title mb-3 mt-2'> Types of Software Testing we offer</h4>
                                        <hr/>

                                    </div>
                                    <div className="row mt-5px-4">
                                        <div className="row section-title mb--20 mb_sm--0">
                                            <div className="col-sm px-4 border  btn-primary ">
                                                <h6 className='head pt-1 text-center'>Functional</h6>
                                                <p className='para1b'>We excel in understanding your product quickly and with minimal amount documentation and hand-holding. We work closely with you to ensure that all functional requirements, including User Experience and Business Logic, are met.</p>
                                            </div>
                                            <div className="col-sm px-4 border btn-primary ">
                                                <h6 className='head pt-1 text-center'> Perfomance</h6>
                                                <p className='para1b'>We have the skills and methodology to plan, conduct, and chart the results of in-depth testing of scalability and resource usage of your software solution. We conduct Load, Stability, Stress, Volume and Configuration testing and create detailed charts and graphs to illustrate the results for your stakeholders</p>
                                            </div>
                                            <div className="col-sm px-4 border  btn-primary ">
                                                <h6 className='head pt-1 text-center'>Security</h6>
                                                <p className='para1b'>Identify and eliminate application and network vulnerabilities to comply with regulatory requirements and mitigate risk.</p>
                                            </div>
                                        </div>


                                        <div className="row section-title mb--20 ">
                                            <div className="col-sm px-4 border  btn-primary ">
                                                <h6 className='head pt-1 text-center'> Compatibility</h6>
                                                <p className='para1b'>Ensure data integrity, flawless functionality and compatibility across a variety of platforms, devices, hardware, OSs. Particularly common is testing compatibility between multiple browsers running on a variety of desktop and mobile environments.</p>
                                            </div>
                                            <div className="col-sm px-4 border btn-primary ">

                                                <h6 className='head pt-1 text-center'> Usability</h6>
                                                <p className='para1b'>Create solutions that customers love. We help bring the best in your systems by testing UI flow, consistency, appearance, congruence, and more.</p>
                                            </div>
                                            <div className="col-sm px-4 border  btn-primary ">
                                                <h6 className='head pt-1 text-center'> Localization</h6>

                                                <p className='para1b'>We test language, characters, idioms, language-based layout and information flow to ensure that your localized software works perfectly in all of its multi-national incarnations. We are well versed in cultural peculiarities of the local audience to help you customize the software, apps and websites for customers in a particular location.</p>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 3*/}
            </div >
            <div div className='p-3' > <ContactButton /></div>
        </div >
    )
}

export default Qatesting;
