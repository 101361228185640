import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import './customStyles.scss';
import ContactButton from './contactbutton';



const Engagementmodels = () => {


    return (
        <div className="active-dark  text-white">




            {/* Start Service Area  */}
            <div id="engagementmodels" className="fix">
                <div className="service-area creative-service-wrapper ptb--20 bg-primary color-white" >
                  <div className='container'>
                    <div className="text-center1 section-title mb--30 mb_sm--0">
                                        <h2 className="title" font color="#002060">Engagement Models</h2>
                                    </div>
                        <div className="row section-title mb--30 mb_sm--0">
                        
                                   
                                    
                                        <div className="col-sm px-4 border  btn-primary">
                                            <h5 className='pt-1'>TIME & MATERIALS</h5>
                                            <p1 className="para1">Ideal for flexible engagements, such as discovery, rapid prototyping and maintenance, our T&M model allows you to quickly scale up and down your commitment.</p1>
                                        </div>

                                        <div className="col-sm px-4 border btn-primary">

                                            <h5 className='pt-1'>FIXED PRICE</h5>
                                            <p1 className="pt-1 para1 ">Our Fixed Price model is ideal for projects with clearly defined objectives and milestones. We provide detailed proposals, schedules and deliverables.</p1>
                                        </div>
                                        <div className="col-sm px-4 btn-primary">
                                            <h5 className='pt-1'>RETAINER AGREEMENT</h5>
                                           
                                            <p1 className="para1">Indibridge works with a number of clients on monthly and annual retainer agreements. This structured engagement is ideal for project kick-offs and ongoing work</p1>
                                        </div>

                                        <div className="col-sm px-4 border  btn-primary">
                                            <h5 className='pt-1'>HYBRID</h5>
                                            <p1 className="para1">Because all projects are different, we offer different pricing and engagement models. A hybrid models offers the perfect blend of structure and flexibility.</p1>

                                        </div>
                                    
                                
                            </div>
                        </div>
                    </div>
            </div>
            {/* End Service Area  
            <div className='p-3'><ContactButton /></div>
            {/* Start Back To Top */}
            {/*} <div className="backto-top">
                <ScrollToTop style={{ bottom: '120px' }} showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

        </div>
    )
}

export default Engagementmodels;
