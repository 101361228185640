import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import './customStyles.scss';
import ContactButton from './contactbutton';
import Ourservices from './ourservices';
import Engagementmodels from './engagementmodels'







const About = () => {

    let title = '',
        description = `TALK TO US FOR BESPOKE TELECOM AND IOT <b>SOLUTIONS.`;
    return (
        <div className="bg-white">
            <Helmet pageTitle="Indibridge" />


            {/* Start About Area */}

            <div id="about" className="fix">
                <div className="about-area ptb--20 ">
                    <div className="about-wrapper bg-primary">
                        <div className="container ">
                            <div className="row ">

                                <div className="col-lg pt-5 ">
                                    <div className="about-inner inner">
                                        <div className="section-title">

                                            <div className='titleMainheder'>
                                                <h4 className='  title mb-3 mt-2'> What We Do</h4>
                                            </div>



                                            <div className="row   px-3 paracenter">
                                                <div className=" ">
                                                    <p className="para2 pt-2  ">We deliver end-to-end software development, quality assurance and support solutions to clients ranging from Fortune 500 companies to start-ups in all stages of funding.</p>
                                                </div>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </div>

            {/*  Section 2*/}
            <div className="service-area creative-service-wrapper ptb--30 bg_color--8">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title mb--10 mb_sm--0">

                                {/*Digital Transformation Services*/}

                                <div className="row px-4">
                                    <div className="col-sm-12 col-md-12 col-lg-12 ">
                                        <div id="">
                                            <p className='para1b  pt-2'>
                                                Indibridge is a premier full-service Development and Quality Assurance partner, specializing in the development of time-sensitive and innovative SaaS, mobile and on-premises solutions. For our client’s software is a critical success factor. To every project, we bring a combination of domain expertise, exceptional engineering talent, rigorous best practices, commitment to protection of client’s IP and high standards of accountability.
                                            </p>
                                            <p className='para1b  pt-2'>
                                                Indibridge has successfully delivered to spectrum of premier clients varying from telecom companies, SaaS, financial services and educational institutions.
                                            </p>
                                            <p className='para1b  pt-2'>
                                                At Indibridge we have perfected an efficient, economical approach for entering and exiting development and QA processes at the right time, using the right resources. Our extensive experience and track record has equipped us with the ability to provide rapid, cost-effective solutions that blend seamlessly with the product and business strategy, infrastructure analysis, architecture, UX design, rapid development, functional QA or all of the above to our clients.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Section 3*/}

            {/** Start OurService */}
            <div className="service-area creative-service-wrapper ptb--20  bg-primary color-white" >
                <div className="section-title mb--30 mb_sm--0">
                    <div className='container'>
                        <div className="titleMainheder">
                            <h2 className="title mb-3 mt-2"> Our Services</h2>
                            <hr /> 
                        </div><br />
                        <div className='row px-6'>
                            <div className='col-lg-4 text-center '>
                                <img className="rounded  w-50 h-50" src="./assets/images/about/Cloud4.png" alt="Cloud Migration Images" /><br />
                                <div>
                                    <h5 className='head mt-5 text-center'>Cloud Migration & Administration</h5>
                                </div>
                            </div>
                            <div className='col-lg-4 text-center'>
                                <img className=" rounded  w-50 h-50 " src="./assets/images/about/MobileAppDevMaint3.png" alt="Mobile App Dev Images" /><br />
                                <div>
                                    <h5 className=' head mt-5 text-center'>Mobile Applications Dev & Maintenance (iOS,Android,Ionic,Cordova,Flutter)</h5>
                                </div>
                            </div>
                            <div className='col-lg-4 text-center'>
                                <img className=" rounded  w-50 h-50 " src="./assets/images/about/RemoteDevOpsSupport3.png" alt="Remote DevOps Support Images" /><br />

                                <div>
                                    <h5 className='head mt-5 text-center'>Remote DevOps Support</h5>
                                </div>
                            </div>
                        </div>
                        <div className='row px-4'>

                            <div className='col-lg-6 text-center'>
                                <img className="rounded  w-50 h-50" src="./assets/images/about/RemoteDeliveryCenterServices3.png" alt="Remote Delivery Center Services Images" /><br />
                                <div>
                                    <h5 className='head mt-5 text-center'>Remote Delivery Center Services</h5>
                                </div>
                            </div>

                            <div className='col-sm-12 col-md-6 col-lg-6 text-center p-2'>

                                <img className=" rounded  w-50 h-50" src="./assets/images/about/EnterpriseAppDevConsulting3.png" alt="Remote Delivery Center Services Images" /><br />
                                <div>
                                    <h6 className='head   '>Enterprise Application Development & Consulting (Middleware & Services-AngularJS, ReactJS, .net,PHP,Java,MongoDB,MySQL,MSSQL,C#,NodeJS,WSO2,Drupal) </h6>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/** End OurService */}
                {/* Satrt Engagement Models*/}
                <div className="service-area creative-service-wrapper ptb--70 pb--20 bg_color--8" >
                    <div className='container'>
                        <div className="titleMainheder">
                            <h4 className="title1 mb-3 mt-2 "> Engagement Models</h4>
                            <hr /> 
                        </div>
                        <br />
                        <div className="row section-title mb--30 mb_sm--0 px-4">
                            <div className="col-sm ml-1 border  btn-primary">
                                <h5 className=' head pt-1 text-center'> Time & Materials</h5>
                                <p className="para1b">Ideal for flexible engagements, such as discovery, rapid prototyping and maintenance, our T&M model allows you to quickly scale up and down your commitment.</p>
                            </div>

                            <div className="col-sm ml-1 border btn-primary">

                                <h5 className='head pt-1 text-center'> Fixed Price</h5>
                                <p className="pt-1 para1b ">Our Fixed Price model is ideal for projects with clearly defined objectives and milestones. We provide detailed proposals, schedules and deliverables.</p>
                            </div>
                            <div className="col-sm ml-1 btn-primary">
                                <h5 className='head pt-1 text-center'> Retainer Agreement</h5>

                                <p className="para1b">Indibridge works with a number of clients on monthly and annual retainer agreements. This structured engagement is ideal for project kick-offs and ongoing work</p>
                            </div>

                            <div className="col-sm ml-1 border  btn-primary">
                                <h5 className='head pt-1 text-center'> Hybrid</h5>
                                <p className="para1b">Because all projects are different, we offer different pricing and engagement models. A hybrid models offers the perfect blend of structure and flexibility.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Engagement Models*/}
            </div>
            <div className='p-3'><ContactButton /></div>
            {/* End About Area 
            <FooterTwo />
            {/* Start Back To Top 
            <div className="backto-top">
                <ScrollToTop style={{ bottom: '120px' }} showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

        </div>
    )
}

export default About;
