import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import ContactButton from "../dark/contactbutton";
import './customStyles.scss';



const SoftDevBFSI = () => {


    return (

        <div className=''>
            {/* Start Service Area  */}
            <div id="services" className="fix">
                <div className="service-area creative-service-wrapper pb--20 pt--70 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>
                                    {/*Section 1*/}
                                    <div className="titleMainheder">
                                        <h4 className="title mb-3">Software Development for BFSI Sector</h4>
                                    </div>
                                    <div className=' px-3 paracenter'>
                                        <div>

    
                                            <h6 className='head ptb-1 text-center'><b>Fueling Fintech Innovation</b></h6>
                                            <p className="para2 pt-2">With our experience and spotless security track record, indibridge is helping financial,
                                                banking, and insurance companies to future-proof their technology solutions and deliver robust and seamless experiences to customers. We develop secure, compliant and fast software web and mobile applications tailored to unique needs of each client be it a startup of a large enterprise.</p>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-area creative-service-wrapper ptb--30 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb_sm--0">
                                    <div className="text-center1">

                                    </div>
                                    {/*Section 1*/}

                                    <div className='col-sm text-center '>
                                        <div>
                                            <p className="para1b pt-2">Innovations in financial technology are revolutionizing the way we manage and engage with our finances. With complex and constantly changing global regulatory environments to comply with and utmost importance of data and application security, choosing the right software development partner who keeps pace with the latest technology advancements within the framework of compliance and application security is critical for business success.FinTech software solutions are different in several ways and require a stable, scalable and secure architecture, stringent data protection, high performance low latency solutions and countless third-party integrations.Indibridge has good experience developing, modernizing and supporting complex FinTech applications ensuring that they are fast, reliable, secure and scalable. Our expert software development, Cloud, QA, and DevOps teams with in-depth domain knowledge seamlessly blend with your business and technology processes to help solve specific technical challenges or bring innovative FinTech products to the market.</p>
                                            {/*<p className="para1 pt-2">
                                                Innovations in financial technology are revolutionizing the way we manage and engage with our finances. With complex and constantly changing global regulatory environments to comply with and utmost importance of data and application
                                                security, choosing the right software development partner who keeps pace with the latest technology advancements within the framework of compliance and application security is critical for business success.
                                            </p>
                                            <p className="para1 pt-2">
                                                FinTech software solutions are different in several ways and require a stable, scalable and secure architecture, stringent data protection, high performance low latency solutions and countless third-party integrations.
                                            </p>
                                            <p className="para1 pt-2">
                                                Indibridge has good experience developing, modernizing and supporting complex FinTech applications ensuring that they are fast, reliable, secure and scalable. Our expert software development, Cloud, QA, and DevOps teams with in-depth domain knowledge seamlessly blend with your business and technology processes to help solve specific technical challenges or bring innovative FinTech products to the market.
    </p>*/}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end Section 1*/}
                {/*Section 2*/}

                <div className="service-area creative-service-wrapper ptb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">

                                        <h4 className=' title mb-3 mt-2'> FinTech Software Development Services</h4>
                                        <hr />
                                    </div>

                                    <div className="rowpx-4">
                                        <div className="col-sm-8 ">
                                            <ul className='listalign'>
                                                <li className=''><i class="bi bi-check-circle-fill px-2"></i>FinTech Web and Mobile Application Development</li>
                                                <li className=''><i class="bi bi-check-circle-fill px-2"></i>Architecture Design</li>
                                                <li className=''><i class="bi bi-check-circle-fill px-2"></i>Website UX and UI Services</li>
                                                <li className=''><i class="bi bi-check-circle-fill px-2"></i>Big Data and Analytics Solutions</li>
                                                <li className=''><i class="bi bi-check-circle-fill px-2"></i>Cloud Engineering</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-5  ">
                                            <ul className='listalign'>
                                                <li className=''><i class="bi bi-check-circle-fill px-2"></i>Machine Learning and Al</li>
                                                <li className=''><i class="bi bi-check-circle-fill px-2"></i>Third-party Application Integration</li>
                                                <li className=''><i class="bi bi-check-circle-fill px-2"></i>Quality Assurance and Testing</li>
                                                <li className=''><i class="bi bi-check-circle-fill px-2"></i>24x7 Maintenance and Support</li>
                                                <li className=''><i class="bi bi-check-circle-fill px-2"></i>IoT Solutions</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 2*/}
                {/*  Section 3*/}
                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">


                                        <h4 className=' title1 mb-3 mt-2'> Application Security Services</h4>
                                        <hr />
                                    </div>
                                    <div className="rowpx-4">
                                        <div className="col-sm-12">

                                            <p className="para1b mt-3">With growing amounts of collected data and many fintech companies undergoing digital transformation the security of the data and applications are among the biggest challenged faced by finance and banking industry.
                                            </p>
                                        </div>


                                        <div className="col-sm-10  ml-3  ">
                                            <h5 className='headbgb mt-3 pb-2'><b>Indibridge provides a full range of services to ensure app and data security</b></h5>

                                            <ul className='listalign'>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Secure Architecture Design</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Security Code Reviews</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Encryption of Data</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Cloud Security Assessment</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Penetration Testing</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Security Consulting</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 3*/}
                {/*Section 4*/}

                <div className="service-area creative-service-wrapper ptb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className='titlecenter'>
                                        <h4 className=' title mb-3 mt-2'> Digital Transformation</h4>
                                        <hr />
                                    </div>
                                    <div className="row">
                                        <div className='col-sm-6 col-md-6 col-lg-7'>

                                            <p className="para2 ptb-1">Rapidly changing market conditions and growing customer demands for service and product interconnectivity, availability and personalization are reshaping financial and banking industry and putting pressure on FinTech companies to innovate. Regardless of whether a company is a digital disruptive startup born less than a decade ago or an established enterprise catching up to digital transformation, none remain unaffected by the shift in consumer expectations and competitive landscape.</p>

                                            <p className="para2  ptb-1 ">To help its clients go through a Digital Transformation efficiently or to digitize and modernize parts of the infrastructure we provide custom Digital Transformation solutions tailored to the needs of every client. And, because it is a journey, not a destination, you need an experienced team to help you take advantage of
                                                new technologies and reap the benefits of being a digital enterprise. We promptly act on your Vision, and realize it in a Secure, Reliable and Scalable way.
                                            </p>
                                        </div>
                                        <div className="col-sm-6 col-md-5 col-lg-5  ">
                                            <img className="DigitalTransform " src="./assets/images/bg/DigitalTransformation.jpg" alt="DigitalTransformation Images" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 4*/}
                {/*  Section 5*/}
                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">

                                        <h4 className=' title1 mb-3 mt-2'> Digital Transformation Services</h4>
                                        <hr />

                                    </div>
                                    <div className="rowpx-4">
                                        <div className="col-sm-4  ">

                                            <ul className='listalign'>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Legacy Application Modernization</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Cloud Migration and Enablement</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Third-Party Integration</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Cloud Engineering</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-6    ">


                                            <ul className='listalign'>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>DevOps Services</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Big Data Warehousing</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Business Intelligence and Predictive Analytics Solutions</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 5*/}



            </div >
            <div div className='p-3' > <ContactButton /></div>
        </div >
    )
}

export default SoftDevBFSI;

