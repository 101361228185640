import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import ContactButton from "../dark/contactbutton";
import './customStyles.scss';



const Devopsservice = () => {


    return (

        <div className=''>
            {/* Start Service Area  */}
            <div id="services" className="fix">
                <div className="service-area creative-service-wrapper pt--70 pb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>
                                    {/*Section 1*/}
                                    <div className="titleMainheder">
                                        <h4 className="title mb-3">DevOps</h4>
                                    </div>
                                    <div className=" px-3 paracenter ">

                                        <p className="para2 pt-2">Achieve a greater business agility and a faster time to market by eliminating bottlenecks in software development with indibridge’s DevOps Services. Continuous Delivery makes staying ahead of the competition and impressing your customers with products they really want a breeze.</p>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end Section 1*/}
                {/*Section 2*/}

                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>


                                    <div className="row">
                                        <div className="col-sm-12 ">

                                            <h4 className=' title1 mb-3 mt-2'> DevOps Assessment & Strategy</h4>
                                            <hr />
                                            <ul className='listalign'>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Assess DevOps maturity level including DevOps culture, processes, and tools</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Audit DevOps existing practices, infrastructure, and existing development pipeline</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Visualize and define an agile transformation roadmap tailored to your organizational needs and the pace of your delivery and innovation</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Identify DevOps metrics, tools and processes</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Develop a transformation timeline and define required skills and resources to achieve business specific goals on budget</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Recommend an optimal DevOps toolchain</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-12  ">

                                            <h4 className=' title1 mb-3 mt-2'> DevOps Automation</h4>
                                            <hr />
                                            <ul className='listalign'>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>CI/CD pipeline Creation and Maintenance</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Continuous Delivery pipeline automation and optimization</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Version control and configuration management</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Application deployment automation</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Infrastructure provisioning and end-to-end infrastructure management</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Test and QA automation, code quality control automation</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Centralized log tracking and management</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 2*/}
                {/*  Section 3*/}
                <div className="service-area creative-service-wrapper ptb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>

                                    <div className="text-center1">

                                        <h4 className=' title mb-3 mt-2'> DevOps Management</h4>
                                        <hr />
                                    </div>
                                    <div className="row mt-5px-4">
                                        <div className="col-sm px-4 border btn-primary">
                                            <h6 className='head text-center'>Continuous Learning</h6>
                                            <p className="para1b">In the world of Cloud DevOps tools, platforms and best practices are complex and are rapidly evolving. Continuous learning keeps indibridge teams up-to-the-minute on the latest technologies, tools, automation and development methodologies.</p>
                                        </div>
                                        <div className="col-sm px-4 border btn-primary">
                                            <h6 className='head text-center'>Continuous  Integration  Services</h6>
                                            <p className="para1b">We assist your core team in ensuring a full- fledged integrated functioning of the delivery pipeline automation cycle and in adapting your automated deployment cycle to changes.</p>
                                        </div>
                                        <div className="col-sm px-4 border btn-primary ">
                                            <h6 className='head text-center'>Continuous Delivery Pipeline Management & Optimization</h6>
                                            <p className="para1b">We take care of release management, continuous deployment, replica environment, new server setup, change management and performance optimization on an ongoing basis.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 3*/}
                {/*Section 4*/}

                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>

                                    <div className="text-center1">

                                        <h4 className=' title1 mb-3 mt-2'> Indibridge’s Agile DevOps-as-a-Service Practice</h4>
                                        <hr />
                                    </div>
                                    <div className="rowpx-4">
                                        <div className="col-sm-5 mt-3 ">

                                            <ul className='listalign'>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Devops maturity assessment</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Devops org transformation consulting</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Devops training with our partners</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Agile + devops team upskilling</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Devops team augmentation</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Migrations with devops best practices</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Ci/cd pipeline creation and maintenance</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Cloud agnostic devops implementations</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-6 mt-3 ">

                                            <ul className='listalign'>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Open source and commercial tools implementations</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Automation (infrastructure as a code, qa)</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Application performance monitoring</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Devops processes effectiveness measurement</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Automated delivery, deployment and rollbacks</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>24x7 monitoring and support</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Site reliability engineering</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 4*/}

                {/* Start Section 5 */}
                <div className="service-area creative-service-wrapper ptb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>

                                    <div className="text-center1">

                                        <h4 className=' title mb-3 mt-2'> Indibridge’s DevOps Practice gives you</h4>
                                        <hr />
                                    </div>
                                    <div className="rowpx-4 mt-5">
                                        <div className="row section-title mb--20 mb_sm--0">
                                            <div className="col-sm px-4 border  btn-primary">
                                                <h6 className='head pt-1 text-center'>A Solid Plan </h6>
                                                <p className="para1b ">for a painless and predictable move to DevOps practices under AWS, Google Cloud or Azure – not as a one size fits all, but tailored specifically to the state of your release and deployment pipeline.</p>
                                            </div>
                                            <div className="col-sm px-4 border btn-primary">
                                                <h6 className='head pt-1 text-center'>Automation & Testing</h6>
                                                <p className="para1b ">We’ll help you build or automate your CI/CD pipeline as well as application and environment testing processes for fast, reliable and predictable releases.</p>
                                            </div>
                                            <div className="col-sm px-4 border btn-primary">
                                                <h6 className='head pt-1 text-center'>Optimization</h6>
                                                <p className="para1b ">of the costs of using a cloud provider of your choice, and the ability to adjust your product architecture to minimize those costs.</p>
                                            </div>
                                            <div className="col-sm px-4 border  btn-primary">
                                                <h6 className='head pt-1 text-center'>Integration</h6>
                                                <p className="para1b ">of our DevOps personnel and/or assistance in training your people to keep DevOps running smoothly once you are up and running.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* End Section 4*/}
                {/*Section 4*/}

                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="titlecenter">
                                        <h4 className=' title1 mb-3 mt-2'> DevOps as a Service</h4>
                                        <hr />
                                    </div>
                                    <div className="row">
                                    <div className=" col-sm-6 col-md-6 col-lg-6">

                                        <p className="para1b pt-3">If your strategy focuses on including DevOps as a part of a larger cloud initiative, we have the technical skills and experience to help your team move to a DevOps development and delivery model. Our DevOps engineers will work with your team on defining a workflow and tool chain, including CI/CD, continuous testing, versioning, and orchestration, writing the scripts needed to automate the workflow, and providing recommendations and implementation on security and performance.</p>
                                        <p className="para1b mt-3">No matter where Your Company is in its evolution to DevOps and the Cloud, Indibridge can support its move to CI/CD and Agile and execute any phase of the development lifecycle.</p>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-5 imgpad ">
                                        <img className="cloudMigrationServ " src="./assets/images/bg/DevOpsasaService.jpg" alt="DevOpsasaService Images" />
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 4*/}
            </div >
            <div div className='p-3' > <ContactButton /></div>
        </div >
    )
}

export default Devopsservice;

