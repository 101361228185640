import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import ContactButton from "../dark/contactbutton";
import './customStyles.scss';



const Softproductdevp = () => {


    return (

        <div className=''>
            {/* Start Service Area  */}
            <div id="services" className="fix">
                <div className="service-area creative-service-wrapper pb--20 pt--70 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>
                                    {/*Section 1*/}
                                    <div className="titleMainheder">
                                        <h4 className="title mb-3">Software Development for Startups</h4>
                                    </div>
                                    <div className="px-3 paracenter">

                                        <p className="para2 pt-2">Indibridge’s custom software development services for startups are designed to transform ideas into innovative, reliable, scalable and successful market-ready solutions. We understand the challenges that entrepreneurs and startups face. With our flexible engagement models, we help our clients speed up time to market, deliver stellar Proof-Of-Concept and MVP solutions fast and on budget, develop new product features, support and maintain your application. Indibridge’s custom software development services for startups are designed to transform ideas into innovative, reliable, scalable and successful market-ready solutions.</p>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end Section 1*/}
                {/*Section 2*/}

                <div className="service-area creative-service-wrapper pt--20 pb--15 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">

                                        <h4 className=' title1 mb-3 mt-2'> Translating Good Ideas into Great Products</h4>
                                        <hr />
                                    </div>
                                    <div>
                                        <p className='para1b ptb-2'>We are very flexible in our business models in working with startups adjusting to their special needs. Our extensive expertise in transforming your ideas into great products includes full-cycle application development services including</p>
                                    </div><br />
                                    <div className="rowpx-4">
                                        <div className="col-sm-8 ">
                                            <ul className='listalign'>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Assessment, roadmap and software architecture design</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Rapid prototyping and Proof-Of-Concept development services</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>MVP design and development</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Front-End, UI and UX services</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Mobile application development</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Cloud engineering and optimization</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-8  ">
                                            <ul className='listalign'>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>DevOps services</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Analytics and Big Data Solutions</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>AI/Machine Learning implementation</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Software quality assurance, testing and test automation</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>24x7 Maintenance and Support</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 2*/}
                {/*  Section 3*/}
                <div className="service-area creative-service-wrapper ptb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row ">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">


                                        <h4 className=' title mb-3 mt-2'> WE WORK WITH STARTUPS AT ABOUT EVERY STAGE</h4>
                                        <hr />
                                    </div>
                                    <div className="row">
                                        
                                            <div className="col-sm col-mg col-lg ml-2 startups rounded-circle  btn-primary ">
                                                <p className='para text-center'><b>Tech Incubator<br /> Projects</b></p>

                                            </div>
                                            <div className="col-sm col-mg col-lg ml-2  startups rounded-circle   btn-primary ">

                                                <p className='para text-center'><b> Bootstrapped</b></p>
                                            </div>

                                            <div className="col-sm col-mg col-lg ml-2  startups rounded-circle  btn-primary ">
                                                <p className='para text-center'><b> Angel Funded </b></p>

                                            </div>
                                            <div className="col-sm col-mg col-lg  ml-2  startups rounded-circle btn-primary ">

                                                <p className='para text-center'><b> Backed By VC<br /> Funding</b></p>
                                            </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 3*/}
                {/*Section 4*/}

                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        <h4 className=' title1 mb-3 mt-2'> Flexible Engagement Models</h4>
                                        <hr />
                                    </div>
                                    <div className="rowpx-4">
                                        <div className="col-sm-6 col-mg-3 col-lg-12 mt-3 mx-2">
                                            <p className="para1b ptb-2 ">We work with you and your team to determine the best engagement approach for your startup. We have a thorough understanding of critical milestones that must be accomplished to advance to the next stage of growth. Our commitment to contemporary Agile practices helps to tackle development goals in a time sensitive manner delivering market ready solutions to our clients.</p>
                                        </div>
                                        <div className="col-sm-6 col-mg-3 col-lg-12  ">
                                            <h5 className='headbgb mt-3 '><b>Indibridge’s flexible engagement models</b></h5>
                                            <ul className='listalign'>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Fixed Price</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>T&M</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Retainer Agreements</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Hybrid Engagements</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 4*/}



            </div >
            <div div className='p-3' > <ContactButton /></div>
        </div >
    )
}

export default Softproductdevp;

