import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import ContactButton from "../dark/contactbutton";
import './customStyles.scss';



const Telecom = () => {


    return (

        <div className=''>
            {/* Start Service Area  */}
            <div id="services" className="fix">
                <div className="service-area creative-service-wrapper pb--20 pt--70 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>
                                    {/*Section 1*/}
                                    <div className="titleMainheder">
                                        <h4 className="title mb-3">Telecom Offerings</h4>
                                    </div>
                                    <div className=" px-3 paracenter ">

                                        <p className="para2 pt-2">As per Gartner's Trends 2021 for Telecom Service Providers (TSPs), “Radical change in the telecom industry is forcing vendors to make strategic choices that reshape business models, products, services and ecosystem participation.</p>

                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end Section 1*/}
                {/*Section 2*/}

                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">

                                        <h4 className=' title1 mb-3 mt-2'> Indibridge has expertise in</h4>
                                        <hr />
                                    </div>
                                    <div className="rowpx-4">
                                        <div className="  ">
                                            <ul className='listalign'>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Deploying End-to-End Platforms which include Mobile Apps, Middleware and Web Applications</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Mobile Application Development using Hybrid Technology</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Supporting 24x7 operations for TSPs</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>DevOps and Cloud Management, which include Amazon, Azure, Oracle and Google</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>API Middleware Development to expose a Telco backend to launch new products and services</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Telecom Consulting, PMO Consulting, Business Analysis and Demand Management Consulting</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Running Business Operations</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Automation Testing and RPA using tools such as Selenium and Automation Edge</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Product Engineering which includes DevOps, Product Releases, Patch fixes, Automation testing</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Shared Services offerings for cost-competitive solutions to TSPs</li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 2*/}
                {/*  Section 3*/}
                <div className="service-area creative-service-wrapper ptb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">


                                        <h4 className=' title mb-3 mt-2'> What makes Indibridge unique</h4>
                                        <hr />
                                    </div>
                                    <div className="rowpx-4">
                                        <div className="col-sm-8 ml-3 ">
                                            <ul className='listalign'>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>We have been handling most customer relationships for an average of at least four years </li>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>Our core technical team offers support to customers on a need basis</li>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>We have deployed large projects on time, on budget, with the best quality</li>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>Security Standards are followed in every project</li>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>Our Partner ecosystem ensures we can offer end to end solutions to our customers</li>
                                                <li><i class="bi bi-check-circle-fill px-2"></i>Our core team has been in the company since its inception</li>
                                            </ul>
                                        </div>
                                       
                                        <p className="para2">We bring Certainty and Predictability to our projects by our senior teams getting involved in the project from the start. Our delivery is process-driven, and our key focus is a happy customer. We have the infrastructure to run large projects based out of Mumbai.</p>
                                        <p className="para2">Our key founders have delivered large and complex system integration projects in India and abroad and mentor the journey of every customer engagement.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 3*/}
                {/*Section 4*/}

                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>

                                    <div className="text-center1">
                                        <h4 className=' title1 mb-3 mt-2'> Mobile Computing and Web Application Development Services</h4>
                                        <hr />
                                    </div>
                                    <div className="row">
                                    <div className="col-sm-6 col-md-5 col-lg-6  ">
                                            <img className="MobileComputing " src="./assets/images/bg/MobileComputing.jpg" alt="MobileComputing Images" />
                                        </div>
                                    <div className="col-sm-6 col-md-5 col-lg-6 ">
                                        <p className="para1b">We have taken the initiative to develop cutting edge solutions to help enterprises become real-time using the latest invents in mobile technologies and wireless communication. Over the past eight years, we have executed more than ten large assignments in mobile computing and wireless services segments for customers worldwide. In addition, we have worked on these technologies for customers in various segments, ranging from ISVs, Service Providers and Enterprise Customers.</p>
                                        <p className="para1b mt-3">We closely track development in mobile technologies and incubate it internally. In several instances, we have influenced the decision to adopt certain mobile technologies to achieve desired solutions, and meet customers' requirements. In addition, we always use our in-depth research in mobile technologies to provide high-end consultancy to our customers. Our experts take an active part in the initial planning stages and remain available throughout the project in a consulting role to ensure smooth execution and timely delivery.</p>
                                    </div>
                                    </div>

                                   

                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 4*/}

                {/* Start Section 5 */}
                <div className="service-area creative-service-wrapper ptb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>

                                    <div className="text-center1">

                                        <h4 className=' title mb-3 mt-2'> Indibridge key expertise</h4>
                                        <hr />
                                    </div>
                                    <div class="table-responsive">
                                        <table className="keytable border btn-primary">
                                            <tr className='headbgb'>
                                                <th width="100px " className='head'>Native Programming</th>
                                                <th width="100px" className='head'>Hybrid Technologies</th>
                                                <th width="100px" className='head'>Front End Technologies<br /> / Bootstrap </th>
                                                <th width="100px" className='head'> Integration</th>
                                            </tr>
                                            <tr>
                                                <td>Windows</td>
                                                <td>Zamarin</td>
                                                <td>Angular Js</td>
                                                <td>Google Pay</td>
                                            </tr>
                                            <tr>
                                                <td>IOS</td>
                                                <td>Google Flutter</td>
                                                <td>React Js </td>
                                                <td>Apple Pay </td>
                                            </tr>
                                            <tr>
                                                <td>Android</td>
                                                <td>IONIC</td>
                                                <td>Drupal </td>
                                                <td>Firebase </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>Cordova</td>
                                                <td> </td>
                                                <td>Clevertap </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td> </td>
                                                <td>Google Analytics </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td> </td>
                                                <td>Payment Gateways  </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <p className="para">We have expertise in building large platforms together with middleware that can integrate into any backend BlackBox.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* End Section 4*/}
                {/*Section 4*/}

                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>

                                    <div className="text-center1">

                                        <h4 className=' title1 mb-3 mt-2'> Case Studies</h4>
                                        <hr />
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="section-title mb--30 mb_sm--0">
                                            <div className="text-center1">
                                                {/*<h2 className="title">Services</h2>*/}
                                            </div>

                                            <div className=" ptb-2">
                                                <div>
                                                    <h6 className='headbgb'><i class="bi bi-check-circle-fill px-2"></i>Tata Teleservices Limited</h6>
                                                </div>
                                                <p className="para1b">Indibridge developed a self-care mobile App platform that includes Android App, IOS App, Admin Portal, Middleware and Integration of Middleware with TTSL backend. Further, it was integrated with Google Maps, Google Analytics, and Payment Gateway to provide a complete customer service experience over a mobile application.
                                                    Mobile App Technology used in this platform was Native programming, and the backend was developed entirely using Java technology.</p>
                                            </div><br />
                                            <div className=" ptb-2">
                                                <div>
                                                    <h6 className='headbgb'><i class="bi bi-check-circle-fill px-2"></i>MMP Mobile Payments Limited</h6>
                                                </div>
                                                <p className="para1b">Indibridge developed a Wallet platform, mRupee (brand name of wallet), which includes Android App, IOS App, Admin Portal, Middleware and Integration of Middleware with a core banking platform from TCS. It was also connected with Google Maps, Google Analytics, Payment Gateway, Goibibo (for travel ticket and hotel booking purchase), and several Advertisement platforms. API aggregators were also integrated with this platform to pay bills for various services to provide a complete wallet service to the customers over a mobile application. It was developed using Mobile App Technology, Native programming, and its backend was developed using Java Technology.</p>
                                            </div><br />
                                            <div className="ptb-1 ">
                                                <div>
                                                    <h6 className='headbgb'><i class="bi bi-check-circle-fill px-2"></i>Invite App</h6>
                                                </div>
                                                <p className="para1b">Indibridge developed a Customer Management Platform for Wipro to manage the various customer visits to Wipro offices. This platform included Android App, IOS App (both for iPhone and iPad), Admin Portal, Middleware and Integration of Middleware with Wipro HRMS systems. This app made accessing the Wipro campus and visiting the management seamless. The visitors now just had to download the app and book a visit. This app guided the visitor from the gate to the building or office of the host. This application was developed using Cordova, and the web parts were designed using AngularJS technology. Its backend database was Postgres SQL, and it was developed on .Net MVC Technology.</p>
                                            </div><br />
                                            <div className="ptb-1">
                                                <div>
                                                    <h6 className='headbgb'><i class="bi bi-check-circle-fill px-2"></i>Learning Platform</h6>
                                                </div>
                                                <p className="para1b">Indibridge developed an end-to-end LMS platform, which includes Mobile Apps, Desktop Apps, and Content Management Systems integrated with Moodle for an Edtechstartup. The intent was to disperse learning and training on mobile applications and make learning easy. This app was developed using native technologies, and the web parts were designed using .Net technology. Its backend Database was MySQL, and it was developed on .Net MVC Technology.</p>
                                            </div><br />
                                            <div className="ptb-1 ">
                                                <div>
                                                    <h6 className='headbgb'><i class="bi bi-check-circle-fill px-2"></i>KidneyKonnect Community App</h6>
                                                </div>
                                                <p className="para1b">For a pharmaceutical company, Indibridge developed a Kidney Patients community app where patients can get information to care when they suffer from Kidney ailments. This application was developed using Native technology and backend on Java technologies and MySQL database.</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 4*/}
            </div >
            <div div className='p-3' > <ContactButton /></div>
        </div >
    )
}

export default Telecom;

