import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import './customStyles.scss';
import ContactButton from './contactbutton'
import Slideshow from "../dark/slideImages";
import './slider.css';


const Clients = () => {



    return (
        <div className="active-dark bg_color--9">
            <Helmet pageTitle="Indibridge" />


            {/* Start Service Area  */}
            <div id="clients" className="fix">
                <div className="service-area creative-service-wrapper ptb--80 bg_color--8" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center1 mb--30 mb_sm--0">
                                    <br /><div>
                                        <h2 className="title">Clients</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service mt-5">
                            <div className="col-lg-12">
                                <div class="container-fluid slider">
                                    <div id="container">
                                        <div className="photobanner" id="css3slider">
                                            <img className="first" src="./assets/images/clients/client1.png" />
                                            <img src="./assets/images/clients/client2.png" />
                                            <img src="./assets/images/clients/client3.png" />
                                            <img src="./assets/images/clients/client4.jpg" />
                                            <img src="./assets/images/clients/client5.png" />
                                            <img src="./assets/images/clients/client6.jpg" />
                                            <img src="./assets/images/clients/client7.png" />
                                            <img src="./assets/images/clients/client8.png" />
                                            <img src="./assets/images/clients/client9.jpg" />
                                            <img src="./assets/images/clients/client10.png" />
                                            <img src="./assets/images/clients/client11.png" />
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="clientLogosWrap">
                                    <div>
                                        <img src="./assets/images/clients/client1.png" />
                                    </div>
                                    <div>
                                        <img src="./assets/images/clients/client2.png" />
                                    </div>
                                    <div>
                                        <img src="./assets/images/clients/client3.png" />
                                    </div>
                                    
                                    <div>
                                        <img src="./assets/images/clients/client4.jpg" />
                                    </div>
                                    <div>
                                        <img src="./assets/images/clients/client5.png" />
                                    </div>
                                    <div>
                                        <img src="./assets/images/clients/client6.jpg" />
                                    </div>
                                    <div>
                                        <img src="./assets/images/clients/client7.png" />
                                    </div>
                                    <div>
                                        <img src="./assets/images/clients/client8.png" />
                                    </div>
                                    <div>
                                        <img src="./assets/images/clients/client9.jpg" />
                                    </div>
                                    <div>
                                        <img src="./assets/images/clients/client10.png" />
                                    </div>
                                    <div>
                                        <img src="./assets/images/clients/client11.png" />
                                    </div>
                                </div> */}


                            </div>

                        </div>

                    </div>

                </div>

            </div>
            {/* End Service Area  */}

            {/* Start Back To Top 
            <div className="backto-top">
                <ScrollToTop style={{ bottom: '120px' }} showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

        </div>
    )
}

export default Clients;
