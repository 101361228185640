import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import ContactButton from "../dark/contactbutton";
import './customStyles.scss';



const EducationELearning = () => {


    return (

        <div className=''>
            {/* Start Service Area  */}
            <div id="services" className="fix">
                <div className="service-area creative-service-wrapper pt--70 pb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>
                                    {/*Section 1*/}
                                    <div className="titleMainheder">
                                        <h4 className="title mb-3">Education & e-Learning</h4>
                                    </div>
                                    <div className='px-3 paracenter'>
                                            <p className="para2 pt-2">At indibridge, we help clients design, develop and support custom education and e-learning solutions to reshape education around the world. We have in-depth domain expertise in leveraging cutting-edge technology to enable Universities, Education and E-Learning companies to launch online learning platforms, reduce administration and collaboration hurdles, setup student recruiting and marketing programs, and streamline assessment processes.</p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end Section 1*/}
                {/*Section 2*/}

                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    
                                    <h4 className=' title1 mb-3 mt-2'>How Can Indibrige Help?</h4>
                                    <hr/>
                                    <div className="text-center1">
                                        <p className='para1b'>We work with EdTech start-ups, Education and E-learning institutions, and enterprises to help develop and customize, optimize support and maintain innovative training and learning solutions tailored to unique needs of each business. We help education companies solve a range of challenges:</p>
                                    </div>
                                    <br />
                                    <div className="rowpx-4">
                                        <div className="  ">
                                            <ul className='listalign'>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Develop personalized learning solutions that off-the-shelf software
                                                    can’t provide
                                                </li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Facilitate and improve learning experience by utilizing a variety of
                                                    learning formats like video, text, and audio or implementing AR/VR
                                                    technology
                                                </li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Digitally transform legacy learning systems to enable scalability and adaptability to student demands</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Improve assessment processes</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Establish an effective communication channel between students and teachers</li>
                                                <li className='text-white'><i class="bi bi-check-circle-fill px-2"></i>Enable students to learn though their mobile devices, including BYOD
                                                    and BYOA implementation
                                                </li>
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 2*/}
                {/*  Section 3*/}
                <div className="service-area creative-service-wrapper ptb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        
                                        <h4 className=' title mb-3 mt-2'>Education and E-Learning SOFTWARE DEVELOPMENT EXPERTISE</h4>
                                        <hr/>
                                    </div><br/>
                                    <div class="px-5">
                                        <div class="row">
                                            <div class=" col-sm-8 col-mg-6 col-lg rounded-circle  btn-primary ml-2 mbt-3">
                                                
                                                <h6 className="head pt-4   text-center" >E-Learning Software </h6>
                                            </div>
                                            <div class=" col-sm-8 col-mg-6 col-lg rounded-circle  btn-primary ml-2 mbt-3">
                                                
                                                <h6 className="para2 pt-4 text-center ">Online Training Platforms</h6>
                                            </div>
                                            <div class=" col-sm-8 col-mg-6 col-lg rounded-circle  btn-primary ml-2 mbt-3">
                                                
                                                <h6 className="head mt-3 pb-2 text-center">LMS (Learning Managment Systems)</h6>
                                            </div>
                                            <div class=" col-sm-8 col-mg-6 col-lg rounded-circle  btn-primary ml-2 mbt-3">
                                                
                                                <h6 className="head pt-4 text-center">Mobile EDTech Applications</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-3px-4">
                                        <div class="row">
                                            <div class=" col-sm-8 col-mg-6 col-lg rounded-circle  btn-primary ml-2 mbt-3">
                                                
                                                <h6 className="head mt-3 pb-2 text-center">Student Onboarding and Management Software</h6>
                                            </div>
                                            <div class=" col-sm-8 col-mg-6 col-lg rounded-circle  btn-primary ml-2 mbt-3">
                                               
                                                <h6 className="head mt-3 pb-2 text-center">Assessment Reporting And Analytics</h6>
                                            </div>
                                            <div class=" col-sm-8 col-mg-6 col-lg rounded-circle  btn-primary ml-2 mbt-3">
                                               
                                                <h6 className="head pt-4 text-center">Custom ERP Solutions</h6>
                                            </div>
                                            <div class="col-sm-8 col-mg-6 col-lg rounded-circle  btn-primary ml-2 mbt-3 ">
                                               
                                                <h6 className="head mt-3 pb-2 mt-4 text-center">Corporate E-Learning Platforms</h6>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 3*/}




            </div >
            <div div className='p-3' > <ContactButton /></div>
        </div >
    )
}

export default EducationELearning;

