import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import ContactButton from "../dark/contactbutton";
import './customStyles.scss';



const Cloudconsultingmigration = () => {


    return (

        <div className=''>
            {/* Start Service Area  */}
            <div id="services" className="fix">
                <div className="service-area creative-service-wrapper pt--70 pb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>
                                    {/*Section 1*/}
                                    <div className="titleMainheder">
                                        <h4 className="title mb-3">Cloud Consulting & Migration</h4>
                                    </div>
                                    <div className=" px-3 paracenter ">

                                        <div>
                                            <p className="para2">Whether you are already in the Cloud, considering Cloud Migration or looking to develop a Cloud-Native application, indibridge will back you up with our in-depth cloud engineering expertise and years of experience modernizing legacy solutions and developing innovative applications for companies ranging from startups to large enterprises. We excel in private, hybrid and public cloud environments helping our clients build high-performance scalable and secure solutions to maximize return on cloud initiatives, increase business agility, reduce costs and lower risks.</p>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end Section 1*/}
                {/*Section 2*/}

                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white">
                    <div className="container">


                        <div className="section-title mb--30 mb_sm--0">
                            <div className="text-center1">
                                {/*<h2 className="title">Services</h2>*/}
                            </div>

                            <div className="text-center1">

                                <h4 className=' title1 mb-3 mt-2'> How Can We Help</h4>
                                <hr />
                            </div><br />
                            <div className="rowpx-4">
                                <div className="row section-title mb--20 ">
                                    <div className="col-sm px-4 border  bg-white text-dark">
                                        <h5 className='head pt-1 text-center'> Cloud Consulting Services</h5>
                                        <ul className='ul1 listalign'>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Cloud Readiness Assessment</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Cloud Migration</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Technology Evaluation</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Architecture and Roadmap Design</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Proof-of-Concept Development</li>
                                        </ul>
                                    </div>
                                    <div className="col-sm px-4 border bg-white text-dark">
                                        <h5 className='head pt-1 text-center'> Cloud Migration Services </h5>
                                        <ul className='ul1 listalign'>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Cloud Migration Strategy</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Application Migration</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Data Migration</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Cloud Optimization and Testing</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Legacy Application Modernization</li>
                                        </ul>
                                    </div>
                                    <div className="col-sm px-4 border  bg-white text-dark">
                                        <h5 className='head pt-1 text-center'> Cloud Development Services</h5>
                                        <ul className='ul1 listalign'>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Cloud-Native Software Development</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Application Containerization</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Multi-tenant Cloud Solutions</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Serverless Architecture and Development</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Cloud Services Integration</li>
                                        </ul>
                                    </div>
                                </div>


                                <div className="row section-title mb--20 ">
                                    <div className="col-sm px-4 border  bg-white text-dark">
                                        <h5 className='head pt-1 text-center'> Devops Services</h5>
                                        <ul className='ul1 listalign'>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>QA Automation</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>CI/CD Implementation</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Agile Deployment</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Microservices Architecture</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>24*7 Maintenance and Support</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Infrastructure Optimization</li>
                                        </ul>
                                    </div>
                                    <div className="col-sm px-4 border bg-white text-dark">
                                        <h5 className='head pt-1 text-center'> Cloud Security Services</h5>
                                        <ul className='ul1 listalign'>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Regulatory Compliance</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Data Security</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Data Encryption</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Identity Control and Management</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Cloud Security Assessment</li>
                                        </ul>
                                    </div>
                                    <div className="col-sm px-4 border  bg-white text-dark">
                                        <h5 className='head pt-1 text-center'> Kubernetes Services</h5>
                                        <ul className='ul1 listalign'>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Kubernetes Consulting</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Managed Kubernetes Services</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Container-based Workload Deployment</li>
                                            <li><i class="bi bi-check-circle-fill px-2"></i>Monitoring and Support</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* End Section 2*/}
                {/* Start Section 3 */}
                <div className="service-area creative-service-wrapper ptb--20 bg-white text-{#002060} ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="titlecenter">
                                        <h4 className=' title mb-3 mt-2'>  Benefits Of Cloud Adoption</h4>
                                        <hr />

                                    </div>
                                    <div className="row">
                                        <div className="col-sm-7 px-4 ">
                                            <div id="dts">
                                                <p className="para2">As a cornerstone of any Digital Transformation journey Cloud provides flexibility, scalability and speed for organizations looking to succeed
                                                    and effectively compete in today’s digital world by promptly adapting to changing consumer expectations.

                                                </p>
                                                <p className="para2">Cloud provides several business benefits to large enterprises and startups alike

                                                </p>
                                                <ul className='ul1 listalign'>
                                                    <li><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Reduced Capital and Operational Costs</li>
                                                    <li><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Seamless Scalability on Demand</li>
                                                    <li><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Improved Business Agility</li>
                                                    <li><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Enhanced Data and Application Security</li>
                                                    <li><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Mobility</li>
                                                    <li><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Advanced Business and Consumer Insights and Analytics</li>
                                                    <li><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Business Process Automation</li>
                                                    <li><span class="fa-li"><i class="bi bi-check-circle-fill px-2"></i></span>Competitive Edge</li>

                                                </ul>
                                                <p className="para2">Navigating cloud journey and complexities of cloud infrastructure are not easy tasks. At Indibridge, we are committed to helping you accelerate the journey to the cloud by leveraging our decades-long experience and expertise in converting legacy software into high performance cutting-edge solutions and building scalable, secure and efficient innovative cloud-native applications. We promptly act on your vision and enable you to fully reap business and technology advantages of the Cloud, to maximize investment in technology without compromising on product and software delivery quality</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-5 col-lg-5  ">
                                            <img className="cloudAdoption " src="./assets/images/bg/BenefitsOfCloudAdoption.jpg" alt="BenefitsOfCloudAdoption Images" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 3*/}
                {/*  Section 4*/}
                <div className="service-area creative-service-wrapper ptb--20 bg_color--8 text-white ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>
                                    {/*Digital Transformation Services*/}
                                    <div className="titleMainheder">

                                        <h4 className=' title1 mb-3 mt-2'> Cloud Migration Services</h4>
                                        <hr />
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-md-5 col-lg-5  ">
                                            <img className="cloudMigrationServ " src="./assets/images/bg/CloudMigrationServices.jpg" alt="CloudMigrationServices Images" />
                                        </div>
                                        <div className='col-sm-6 col-md-6 col-lg-7 text-center'>
                                            <p className="para1b pt-5">Successful Cloud Migrations are often more complex than just a simple lift-and-shift. Inefficient migration approach can lead to unplanned costs and be disruptive to businesses. At Indibridge we have developed a tailored cloud migration solutions to help clients effectively and cost-efficiently move to, from and within private, hybrid, and public (AWS, GCP and Azure) cloud environments.</p>
                                            <p className="para1b pt-2">We help companies navigate complex cloud ecosystem and provide end-to-end cloud migration services ranging from cloud readiness assessments and migration planning to migration execution, cloud optimization, and support and maintenance.</p>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 3*/}

                {/* Start Section 4
                <div className="service-area creative-service-wrapper ptb--10 bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        <h5 className='head mt-3'> Agile DevOps Services:</h5>
                                    </div>
                                    <div id="dts">
                                        <div>
                                            <p className='para pt-2'>Digital Transformation is hard to achieve without a solid infrastructure automation foundation and close collaboration between teams. Pairing Cloud with DevOps allows organizations to speed the pace of innovation and better respond to continuous change in market conditions and evolving customer demands. DevOps helps to enable rapid prototyping and faster, more stable software and services delivery.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 4*/}
            </div >
            <div div className='p-3' > <ContactButton /></div>
        </div >
    )
}

export default Cloudconsultingmigration;
