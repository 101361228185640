import React from 'react';
const moment = require('moment');

function ContactForm() {
  return (
    <form  >
        <label htmlFor="item01">
            <input
                type="text"
                name="Name"
                id="item01"
                placeholder="Your Name *"
            />
        </label>

        <label htmlFor="item02">
            <input
                type="text"
                name="Mobile"
                id="item02"
                placeholder="Mobile no *"
            />
        </label>
        <label htmlFor="item03">
            <input
                type="text"
                name="Email"
                id="item03"
                placeholder="Your email *"
            />
        </label> 
        <label htmlFor="item04">
            <textarea
                type="text"
                id="item04"
                name="message"                
                placeholder="Your requirement description"
            />
        </label> 
        <button className="btn-default"  type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit</button>
    </form>
  );
}

function App() {
  return (
    <ContactForm />
  );
}
export default App;