import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import './customStyles.scss';




const Contact = () => {
    
    return (
        <div className="active-dark bg_color--9">
            <Helmet pageTitle="Indibridge" />

            

 {/* Start COntact Area */}
 <div id="contact" className="fix">
 <div className="rn-contact-area ptb--80 bg_color--8">
     <ContactThree contactImages="./assets/images/about/contact.jpg" contactTitle="Reach Us" />
 </div>
</div>
{/* End COntact Area *

<FooterTwo />
{/* Start Back To Top 
<div className="backto-top">
 <ScrollToTop style={{ bottom: '120px' }} showUnder={160}>
     <FiChevronUp />
 </ScrollToTop>
</div>
 End Back To Top */}

</div>
)
}

export default Contact;
