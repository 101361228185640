import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import ContactButton from "../dark/contactbutton";
import './customStyles.scss';



const SoftwareSecurityServices = () => {


    return (

        <div className=''>
            {/* Start Service Area  */}
            <div id="services" className="fix">
                <div className="service-area creative-service-wrapper pb--20 pt--70 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>
                                    {/*Section 1*/}
                                    <div className="titleMainheder">
                                        <h4 className="title mb-3">Software Security</h4>
                                    </div>
                                    <div className='px-3 paracenter'>


                                        <p className="para2 p-2">With a proven expertise in secure software development and software security assurance we help clients to create state-of-the-art secure applications, assess and significantly increase security level of the existing software solutions.</p>
                                        <p className="para2 p-2">Our core competence is Application Security. We transfer this knowledge and best practices to assist clients ranging from startups to large enterprises in a variety of industries in ensuring the protection of their data and applications.</p>
                                        <p className="para2 p-2">Developing secure software is no longer desirable, but absolutely essential. With an increasing Cloud and Data Analytics adoption assessing and ensuring security of the data has become a major concern for companies ranging from early-stage online ventures to large enterprises. Despite common misconception, main security threats arise not from
                                            networking layers and operating systems, but from applications themselves.</p>


                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end Section 1*/}

                {/*  Section 3*/}
                <div className="service-area creative-service-wrapper ptb--20  bg_color--8 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">

                                        <h4 className=' title1 mb-3 mt-2'> Indibrige Security Services</h4>
                                        <hr />
                                    </div>
                                    <div class="container">
                                        <div className="rowpx-4 justify-content-center ">
                                            <div className="row section-title mb--20 mb_sm--0 justify-content-center">
                                                <div className="col-sm-6 col-mg-8 col-lg-3 ml-3 py-3  border  bg-white  iss">
                                                    <h5 className='head pt-1 text-center'>Risk Management And Compliance</h5>
                                                    <p className='para2 '>Build a solid foundation for your compliance requirements (PCI DSS, GDPR, ISO 27001, FFIEC, SOX, HIPAA, etc.)</p>
                                                </div>
                                                <div className="col-sm-6 col-mg-8 col-lg-3  ml-3 py-3 border  bg-white iss">
                                                    <h5 className='head pt-1 text-center'>Application Security Testing</h5>
                                                    <p className='para2 '>Uncover and fix software vulnerabilities with software penetration testing.</p>
                                                </div>
                                                <div className="col-sm-6 col-mg-8 col-lg-3 ml-3 py-3  border  bg-white iss">
                                                    <h5 className='head pt-1 text-center'>Security Services, SDLC Assurance</h5>
                                                    <p className='para2 '>Identify security gaps of your Web, Cloud, Mobile, IoT, or Embedded Software Solutions.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="rowpx-4  justify-content-center ">
                                            <div className="row section-title mb--20 mb_sm--0 justify-content-center ">
                                                <div className="col-sm-6 col-mg-8 col-lg-3 ml-3 py-3  border  bg-white iss1">
                                                    <h5 className='head pt-1 text-center '>Infrastructure Security Consulting</h5>
                                                    <p className='para2'>Build and implement tailored and secure backbone.</p>
                                                </div>
                                                <div className="col-sm-6 col-mg-8 col-lg-3 ml-3 py-3   border  bg-white iss1">

                                                    <h5 className='head pt-1 text-center'>Security Design And Code Reviews</h5>
                                                    <p className='para2'>Future proof applications and reduce security risks.</p>
                                                </div>
         
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 2*/}
                {/*  Section 3*/}
                <div className="service-area creative-service-wrapper ptb--20 bg-white text-{#002060}">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 mb_sm--0">
                                    <div className="text-center1">
                                        {/*<h2 className="title">Services</h2>*/}
                                    </div>
                                    {/*Digital Transformation Services*/}
                                    <div className="text-center1">

                                        <h4 className=' title mb-3 mt-2'> Ensuring Data Security</h4>
                                        <hr />
                                    </div>
                                    <div className=''>
                                        <p className="para2">No matter what web-based application solution you are developing or planning to develop, most likely it will contain sensitive user data that needs to be protected</p>
                                        <p className="para2">With online and mobile payments on the rise, protecting customer data has become important like never before. Business application domain is a special case that requires sophisticated encryption and security algorithms. Applications that store personal information along with the payment information are a subject to multiple compliance regulations like HIPAA and PCI-DSS.</p>
                                        <p className="para2 pt-2">Our process of customer data protection while in transit or when stored in the database without additional hassle to the end user.</p>
                                        <p className="para2" >Security is a critical goal when developing a cloud-based service or application. Indibridge’s experienced development teams can help you to deliver state-of-the-art solutions that are secure and user-friendly without the loss of functionality.</p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 3*/}


            </div >
            <div div className='p-3' > <ContactButton /></div>
        </div >
    )
}

export default SoftwareSecurityServices;
