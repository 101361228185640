import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import './customStyles.scss';
import ContactButton from './contactbutton';




const Ourservices = () => {
    return (
        <div className="service-area creative-service-wrapper ptb--20 bg_color--8">
            {/* Start Service Area  */}
            <div id="ourservices" className="fix">
                <div className="service-area creative-service-wrapper ptb--20 bg_color--8" >
                    <div className="container ">
                        <div className="row">
                            <div className="col-lg-12 px-1">

                                <div className="section-title mb--30 mb_sm--0">
                                    {/*<span className="subtitle">What I can do for you</span>*/}
                                    <div className="titleMainheder">
                                        <h2 className="title1">Our Services</h2>
                                    </div><br />
                                    <div className='row'>
                                        <div className='col-lg-6 mt-5'>
                                            <div className="rounded float-left">
                                                <img className="img-cloud w-100" src="./assets/images/about/CloudMigration.png" alt="Cloud Migration Images" /><br />
                                                <h5 className='ml-5 head'>Cloud Migration & Administration</h5>
                                            </div>

                                        </div>
                                        {/*
                                        <div className='col-lg-6 '>
                                            <h6 className='mt-3 head'>Consulting:</h6>
                                            <ul className='para'>
                                                <li >Cloud Migration Strategy </li>
                                                <li>Cloud Adoption Strategy</li>
                                                <li>Devops Strategy on Cloud</li>
                                                <li>Cloud Stack Technology Consulting   (Product/ Vendor evaluation)</li>
                                                <li>Business process review & re-engineering</li>
                                                <li>IT Strategy & Architecture</li>
                                                <li>Product Licensing & Optimization</li>
                                            </ul>
                                            <h6 className=' head'> Tech CAP</h6>
                                            <ul>
                                                <li>A customized offering to facilitate better absorption & utilization of new technology trends & strategies by enterprises </li>
                                            </ul>
                                            <h6 className=' head'>Cloud Implementation Services </h6>
                                            <ul>
                                                <li>Cloud Migration services - e.g. Amazon to Azure </li>
                                                <li>Cloud Migration Testing Services </li>
                                                <li>Cloud Infrastructure Management </li>
                                                <li>Hypermobility Services - managing multiple cloud vendors remotely </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div>
                                    <h5 className='mt-5 head'>Mobile Applications Dev & Maintenance</h5>
                                        <div className='row'>




                                            <div className='col-lg-6'>
                                                
                                                <h6 className=' head'>  Application Development and Maintenance</h6>
                                                <ul>
                                                    <li>Design, Develop, Test & Deploy custom software applications</li>
                                                    <li>System Integration Services </li>
                                                    <li>MIS, Reports and Dashboards</li>
                                                    <li>App Maintenance & Product Support</li>
                                                    <li>Operations Support</li>
                                                </ul>
                                                <h6 className=' head'>Assurance Services </h6>
                                                <ul>
                                                    <li>Unit Testing, System Integration Testing, User Acceptance Testing</li>
                                                    <li>Selenium Expertise </li>
                                                    <li>Blackbox Testing </li>
                                                    <li>Performance Testing using Jmeter </li>
                                                    <li>VAPT Testing </li>
                                                </ul>
                                                <h6 className='head'>Mobility Services </h6>
                                                <ul>
                                                    <li>Mobile App Development with Middleware for API Integration </li>
                                                    <li>Mobile App Testing </li>
                                                    <li>Native App Development</li>
                                                    <li>Hybrid App Development on IONIC, CORDOVA, Google Flutter </li>
                                                </ul>

                                                <h6 className='head'>Product Development and Release</h6>
                                                <ul>
                                                    <li>MVP Release </li>
                                                    <li>POC and Pilot Management of Product MVP </li>
                                                    <li>End to End Services  - Co-Ideation to Implementation </li>
                                                    <li>Product Management COE </li>
                                                    <li>Roadmap Development </li>
                                                    <li>Product Development and Testing </li>
                                                </ul>
                                                <h6 className='head'>API Development, Testing and Management</h6>
                                                <ul>
                                                    <li>API Integration Services REST, JSON, HTML </li>
                                                    <li>API Middleware Layer Development</li>
                                                    <li>Strategic Partners for WSO2 API Management Software </li>
                                                    <li>API Testing and Publishing Services
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='col-lg-6  '>
                                            <div className=" rounded float-right" >
                                                    <img className="img-mobile w-80 " src="./assets/images/about/MobileAppDev.png" alt="Mobile App Dev Images" /><br />
                                                </div>
                                            </div>

                                        </div>*/}
                                    </div>
                                </div>
                                <div>
                                    <div className='row'>
                                        <div className='col-lg-6 '>
                                            <h5 className='head'>Remote DevOps Support</h5>
                                            <div className="rounded float-left" >
                                                <img className=" w-80 img-remote" src="./assets/images/about/RemoteDevOps.png" alt="Remote DevOps Support Images" /><br />
                                            </div>
                                        </div>
                                        {/*<div className='col-lg-6'>
                                                    <h6 className='mt-5 head'>Consulting Services</h6>
                                                    <ul className='para1'>
                                                        <li>Business process review & re-engineering</li>
                                                        <li>IT Strategy & Architecture</li>
                                                        <li>Product Licensing & Optimization</li>
                                                        <li>Product/ Vendor evaluation</li>
                                                    </ul>
                                                    <h6 className='head'>Application Development and Maintenance </h6>
                                                    <ul>
                                                        <li>Design, Develop, Test & Deploy custom software applications</li>
                                                        <li>System Integration Services </li>
                                                        <li>MIS, Reports and Dashboards</li>
                                                        <li>App Maintenance & Product Support</li>
                                                        <li>Operations Support</li>
                                                    </ul>

                                                    <h6 className='head'>Product Development and Release </h6>
                                                    <ul>
                                                        <li>MVP Release </li>
                                                        <li>POC and Pilot Management of Product MVP </li>
                                                        <li>End to End Services  - Co-Ideation to Implementation</li>
                                                        <li>Product Management COE </li>
                                                        <li>Roadmap Development </li>
                                                        <li>Product Development and Testing</li>
                                                    </ul>
                                    </div>*/}
                                    </div>
                                </div>
                                <div>
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <h5 className='head'>Remote Delivery Center Services</h5>
                                        </div>
                                        <div className='col-lg-6 mt-5'>
                                            <div className="rounded float-right" >
                                                <img className=" w-80 img-remoteCent" src="./assets/images/about/RemoteDeliveryCenter.png" alt="Remote Delivery Center Services Images" /><br />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h5 className='head'>Enterprise Application Development & Consulting</h5>
                                    <div className='row'>


                                        <div className='col-lg-6 mt-5'>
                                            <div className="rounded float-left" >
                                                <img className=" w-80 img-enterprise" src="./assets/images/about/EnterpriseApp.png" alt="Remote Delivery Center Services Images" /><br />
                                            </div>
                                        </div>
                                        {/*<div className='col-lg-6'>

                                                    <h6 className='mt-5 head'>Consulting Services </h6>
                                                    <ul>
                                                        <li>Business process review & re-engineering</li>
                                                        <li>IT Strategy & Architecture</li>
                                                        <li>Product Licensing & Optimization</li>
                                                        <li>Product/ Vendor evaluation</li>
                                                    </ul>
                                                    <h6 className='head'>Application Development and Maintenance </h6>
                                                    <ul>
                                                        <li>Design, Develop, Test & Deploy custom software applications</li>
                                                        <li>System Integration Services </li>
                                                        <li>MIS, Reports and Dashboards</li>
                                                        <li>App Maintenance & Product Support</li>
                                                        <li>Operations Support</li>
                                                    </ul>
                                                    <h6 className='head'>Product Development and Release </h6>
                                                    <ul>
                                                        <li>MVP Release </li>
                                                        <li>POC and Pilot Management of Product MVP </li>
                                                        <li>End to End Services  - Co-Ideation to Implementation </li>
                                                        <li>Product Management COE </li>
                                                        <li>Roadmap Development </li>
                                                        <li>Product Development and Testing </li>
                                                    </ul>
                                                    <h6 className='head'>Mobility Services </h6>
                                                    <ul>
                                                        <li>Mobile App Development with Middleware for API Integration </li>
                                                        <li>Mobile App Testing </li>
                                                        <li>Native App Development</li>
                                                        <li>Hybrid App Development on IONIC, CORDOVA, Google Flutter </li>
                                                    </ul>
                                                    <h6 className='head'>API Development, Testing and Management</h6>
                                                    <ul>
                                                        <li>API Integration Services REST, JSON, HTML</li>
                                                        <li>API Middleware Layer Development</li>
                                                        <li>Strategic Partners for WSO2 API Management Software</li>
                                                        <li>API Testing and Publishing Services</li>
                                                    </ul>
                                                    <h6 className='head'>Analytics</h6>
                                                    <ul>

                                                        <li>Data Analysis</li>
                                                        <li>Predictive Analysis</li>
                                                        <li>AI & Machine Learning</li>
                                                    </ul>
                                                    <h6 className='head'>Technology</h6>
                                                    <ul>
                                                        <li>Comprehensive  experience in .NET, C#, SharePoint, Java J2EE, PHP, MySQL, PostgreSQL, Mongo DB, WSO2,Native Android, Native IOS, Hybrid, Fireworks such as Ionic and Corodova
                                                        </li>
                                                    </ul>
                                </div>*/}
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>



            {/* End Service Area  
            <div className='p-3'><ContactButton /></div>
            {/* Start Back To Top */}
            {/*} <div className="backto-top">
           <ScrollToTop style={{ bottom: '120px' }} showUnder={160}>
               <FiChevronUp />
           </ScrollToTop>
       </div>
       {/* End Back To Top */}

        </div>
    )
}

export default Ourservices;
